import { Alert, Box, Button, Checkbox, Modal, SpaceBetween, TextContent } from '@cloudscape-design/components'
import { useNavigate } from 'react-router-dom'
import { TCCService } from 'services/api'
import { Customer, Tenant } from 'twirp/api'
import { useModalData } from './ModalContext'
import { useState } from 'react'

export const DeleteTenantModalName = 'DeleteTenantModal'

interface DeleteTenantModalProps {
  customer: Customer
  tenant: Tenant
}

export const DeleteTenantModal = (props: DeleteTenantModalProps) => {
  const navigate = useNavigate()
  const handleDeleteTenant = async () => {
    await TCCService.DeleteTenant({
      tenantId: props.tenant.id,
      force: true,
    })
    navigate(`/customers/${props.customer.id}`)
  }

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const { visible, disableModal } = useModalData(DeleteTenantModalName)

  return (
    <Modal
      header="Delete Tenant"
      onDismiss={disableModal}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteTenant} disabled={!confirmDelete}>
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Box padding="s">
        <Box>
          <TextContent>
            <h5>Tenant Name</h5>
            <p>{props.tenant.name}</p>
          </TextContent>
        </Box>

        <Box>
          <TextContent>
            <h5>Tenant ID</h5>
            <p>{props.tenant.id}</p>
          </TextContent>
        </Box>
      </Box>
      <SpaceBetween size="s">
        <Alert type="warning">
          Choosing to delete this tenant will immediately and permanently remove the tenant from TCC.{' '}
          <b>This action cannot be undone</b>. Continue?
        </Alert>
        <Checkbox onChange={({ detail }) => setConfirmDelete(detail.checked)} checked={confirmDelete}>
          Confirm delete
        </Checkbox>
      </SpaceBetween>
    </Modal>
  )
}
