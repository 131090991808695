import { ExpandableSection, Modal, SpaceBetween } from '@cloudscape-design/components'
import { useModalData } from './ModalContext'
import { useCallback, useEffect, useState } from 'react'
import { TCCService } from 'services/api'
import {
  ObjectDataDeployment,
  TemplateFragmentDeployment,
  Tenant,
  TenantDestruction,
  UserProvisioningDeployment,
} from 'twirp/api'
import { ObjectDataDeploymentsTable } from './ObjectDataTableDeployments'
import { TemplateFragmentDeploymentTable } from './TemplateFragmentDeploymentTable'
import { UserProvisioningDeploymentsTable } from './UserProvisioningDeploymentsTable'

export const TenantProvisioningLogModalName = 'TenantProvisioningLogModal'

export const TenantProvisioningLogModal = (props: { tenant: Tenant }) => {
  const { visible, disableModal } = useModalData(TenantProvisioningLogModalName)
  const [fragmentDeployments, setFragmentDeployments] = useState<TemplateFragmentDeployment[]>()
  const [tenantDestructions, setTenantDestructions] = useState<TenantDestruction[]>()
  const [dataDeployments, setDataDeployments] = useState<ObjectDataDeployment[]>()
  const [userProvisioningDeployments, setUserProvisioningDeployments] = useState<UserProvisioningDeployment[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getTenantStatusData = useCallback(async () => {
    if (props.tenant.archived) {
      const destructionData = await TCCService.GetTenantDestructions({
        tenantId: props.tenant.id,
      })
      setTenantDestructions(destructionData.tenantDestructions)
    } else {
      const serviceDeploymentData = await TCCService.GetTemplateFragmentDeployments({
        tenantId: props.tenant.id,
      })
      setFragmentDeployments(serviceDeploymentData.templateFragmentDeployments)
    }

    if (props.tenant.includeObjectDataFromTemplate) {
      const objectDeploymentData = await TCCService.GetObjectDataDeployments({
        tenantId: props.tenant.id,
      })
      setDataDeployments(objectDeploymentData.objectDataDeployments)
    }

    const userProvisioningDeploymentData = await TCCService.GetUserProvisioningDeployments({
      tenantId: props.tenant.id,
    })
    setUserProvisioningDeployments(userProvisioningDeploymentData.userProvisioningDeployments)

    setIsLoading(false)
  }, [props.tenant])

  useEffect(() => {
    if (!visible) {
      return
    }

    getTenantStatusData()
  }, [getTenantStatusData, visible])

  return (
    <Modal
      header="Provisioning Log"
      onDismiss={disableModal}
      visible={visible}
      closeAriaLabel="Close modal"
      size="large"
    >
      <SpaceBetween direction="vertical" size="s">
        <ExpandableSection defaultExpanded={true} headerText="Configuration" variant="navigation">
          <TemplateFragmentDeploymentTable
            serviceStatuses={props.tenant.archived ? tenantDestructions : fragmentDeployments}
            tableVariant="borderless"
            includeHeader={false}
            isLoading={isLoading}
          />
        </ExpandableSection>
        <ExpandableSection defaultExpanded={false} headerText="Data" variant="navigation">
          <ObjectDataDeploymentsTable
            objectDataDeployments={dataDeployments!}
            tableVariant="borderless"
            includeHeader={false}
            isLoading={isLoading}
          />
        </ExpandableSection>
        <ExpandableSection defaultExpanded={false} headerText="Users" variant="navigation">
          <UserProvisioningDeploymentsTable
            userProvisioningDeployments={userProvisioningDeployments!}
            tableVariant="borderless"
            includeHeader={false}
            isLoading={isLoading}
          />
        </ExpandableSection>
      </SpaceBetween>
    </Modal>
  )
}
