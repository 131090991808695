import { Header, Table, StatusIndicatorProps } from '@cloudscape-design/components'
import { TemplateFragment } from 'twirp/api'
import { EmptyTableMessage } from './EmptyTableMessage'
import { StatusIcon } from './StatusIcon'
import { formatServiceName } from 'lib/tenant-services'

export const TemplateFragmentTable = ({ templateFragments }: { templateFragments: TemplateFragment[] }) => {
  const statusMap: { [key: string]: StatusIndicatorProps.Type } = {
    UNKNOWN: 'warning',
    SUCCESS: 'success',
    PENDING: 'pending',
    FAILURE: 'error',
    DESTROY_PENDING: 'pending',
    DESTROY_SUCCESS: 'success',
    DESTROY_FAILURE: 'error',
  }

  return (
    <Table
      columnDefinitions={[
        {
          id: 'serviceName',
          header: 'Service',
          cell: (item) => formatServiceName(item.name),
          sortingField: 'service',
          minWidth: '4rem',
          maxWidth: '4rem',
        },
        {
          id: 'error',
          header: 'Message',
          cell: (item) => item.errorMessage,
          sortingField: 'status',
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => <StatusIcon item={item} mapping={statusMap} timeoutSeconds={300} />,
          sortingField: 'status',
          minWidth: '4rem',
          maxWidth: '4rem',
        },
      ]}
      items={templateFragments}
      loadingText="Loading service statuses"
      trackBy="id"
      visibleColumns={['serviceName', 'status', 'error']}
      header={<Header>Template Services</Header>}
      wrapLines
      empty={<EmptyTableMessage title="No services found" subtitle="" />}
    />
  )
}
