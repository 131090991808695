import { axiosRPC } from 'services/api'
import { useNavigate } from 'react-router-dom'
import { TendoCloudConfiguratorServiceClientJSON } from '../twirp/api.twirp-client'
import * as uuid from 'uuid'
import {
  Button,
  Container,
  Header,
  TextContent,
  Form,
  SpaceBetween,
  FormField,
  Input,
  Box,
  BreadcrumbGroup,
} from '@cloudscape-design/components'
import { useContext, useState } from 'react'
import { FlashbarContext } from 'components/FlashMessage'

export const NewCustomer = () => {
  const { pushMessage } = useContext(FlashbarContext)
  const [customerName, setCustomerName] = useState('')
  const [customerShortName, setCustomerShortName] = useState('')
  const [userFullName, setUserFullName] = useState('')
  const [userEmail, setUserEmail] = useState('')

  const navigate = useNavigate()

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  const shortNameRegex = /^[a-z0-9-]+$/

  const validUserFullName = userFullName !== ''
  const validCustomerShortName = shortNameRegex.test(customerShortName)
  const validUserEmail = emailRegex.test(userEmail)

  const canSubmit = validUserEmail && validUserFullName && customerName !== '' && validCustomerShortName

  const onFinish = async () => {
    const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)

    try {
      await client.CreateCustomer({
        customer: {
          id: '',
          name: customerName,
          shortName: customerShortName,
          accountNumber: uuid.v4(), // TODO: make this a form field when supported
        },
        firstUser: {
          name: userFullName,
          email: userEmail,
        },
      })
      navigate('/')
    } catch (e) {
      pushMessage('error', (e as Error).message)
      window.scrollTo(0, 0)
    }
  }

  return (
    <Box padding="m">
      <BreadcrumbGroup
        items={[
          { text: 'Customers', href: '/customers' },
          { text: 'New customer', href: '/customers/new' },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <Form
        variant="embedded"
        header={<Header variant="h1">New Customer</Header>}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" href="/customers">
              Cancel
            </Button>
            <Button variant="primary" disabled={!canSubmit} onClick={onFinish}>
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="m">
          <Container header={<Header>Basic Information</Header>}>
            <SpaceBetween size="s">
              <FormField label="Name" description="The name of the customer, such as the name of the hospital network.">
                <Input value={customerName} onChange={({ detail }) => setCustomerName(detail.value)} />
              </FormField>
              <FormField
                label="Short name"
                description="Can only contain lowercase alphanumerics and dashes. Used in DNS and Auth0 lookups."
              >
                <Input value={customerShortName} onChange={({ detail }) => setCustomerShortName(detail.value)} />
              </FormField>
            </SpaceBetween>
          </Container>
          <Container
            header={
              <TextContent>
                <Header>Admin User</Header>
                <small>This user will be the bootstrap user who may add additional admins.</small>
              </TextContent>
            }
          >
            <TextContent>
              <SpaceBetween size="s">
                <FormField label="Full name">
                  <Input value={userFullName} onChange={({ detail }) => setUserFullName(detail.value)} />
                </FormField>
                <FormField label="Email">
                  <Input value={userEmail} onChange={({ detail }) => setUserEmail(detail.value)} />
                </FormField>
              </SpaceBetween>
            </TextContent>
          </Container>
        </SpaceBetween>
      </Form>
    </Box>
  )
}
