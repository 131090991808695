import { useContext, useEffect } from 'react'
import { Auth0Context, useAuth0 } from '@auth0/auth0-react'

import AuthenticatedApp from './components/AuthenticatedApp'
import TokenManager from './services/TokenManager'

export default function App(): JSX.Element {
  const auth0Context = useContext(Auth0Context)
  if (!auth0Context) {
    // useUser required Auth0Provider (useAuth0)
    throw new Error(`useUser must be used within a Auth0Provider`)
  }

  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ appState: { returnTo: window.location.pathname } })
    }
  }, [isLoading, loginWithRedirect, isAuthenticated])

  useEffect(() => {
    // set token access method ( we'll use this to retrieve tokens in axios interceptor )
    TokenManager.getInstance().getAccessToken = getAccessTokenSilently
  }, [getAccessTokenSilently])

  useEffect(() => {
    document.title = 'Tendo Cloud Configurator'
  })

  if (isLoading) {
    return <div data-testid="loading">Loading...</div>
  }

  return isAuthenticated ? <AuthenticatedApp /> : <></>
}
