import { Header, StatusIndicatorProps, Table, TableProps } from '@cloudscape-design/components'
import { ObjectDataDeployment } from 'twirp/api'
import { EmptyTableMessage } from './EmptyTableMessage'
import { StatusIcon } from './StatusIcon'

export const ObjectDataDeploymentsTable = ({
  objectDataDeployments,
  tableVariant,
  includeHeader,
  isLoading,
}: {
  objectDataDeployments: ObjectDataDeployment[]
  tableVariant?: TableProps.Variant
  includeHeader?: boolean
  isLoading?: boolean
}) => {
  const statusMap: { [key: string]: StatusIndicatorProps.Type } = {
    UNKNOWN: 'warning',
    SUCCESS: 'success',
    PENDING: 'pending',
    FAILURE: 'error',
    NO_RECORDS: 'success',
    AWAITING_TENANT_CONFIGURATION: 'info',
  }

  return (
    <Table
      columnDefinitions={[
        {
          id: 'createdAt',
          header: 'Started At',
          cell: (item) => item.createdAt?.toLocaleString(),
          sortingField: 'createdAt',
          minWidth: '4rem',
        },
        {
          id: 'objectModel',
          header: 'Object',
          cell: (item) => item.model,
          sortingField: 'model',
          minWidth: '12rem',
        },
        {
          id: 'records',
          header: 'Records',
          cell: (item) => item.numberRecords,
          sortingField: 'numberRecords',
          maxWidth: '6rem',
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => <StatusIcon item={item} mapping={statusMap} />,
          sortingField: 'status',
          minWidth: '4rem',
          maxWidth: '12rem',
        },
      ]}
      items={objectDataDeployments}
      loading={isLoading}
      loadingText="Loading object data"
      trackBy="id"
      resizableColumns
      visibleColumns={['createdAt', 'objectModel', 'records', 'status']}
      header={includeHeader && <Header>Data</Header>}
      wrapLines
      variant={tableVariant}
      empty={<EmptyTableMessage title="No object data deployments found" subtitle="" />}
    />
  )
}
