import { Checkbox, Header, StatusIndicatorProps, Table, TableProps } from '@cloudscape-design/components'
import { ObjectData } from 'twirp/api'
import { EmptyTableMessage } from './EmptyTableMessage'
import { StatusIcon } from './StatusIcon'
import { useState } from 'react'

export const ObjectDataTable = ({
  objectData,
  variant,
}: {
  objectData: ObjectData[]
  variant?: TableProps.Variant
}) => {
  const [showNoRecordObjects, setShowNoRecordObjects] = useState(false)
  const toggleShowNoRecordObjects = () => setShowNoRecordObjects(!showNoRecordObjects)

  const filteredObjectData = showNoRecordObjects ? objectData : objectData.filter((od) => od.status !== 'NO_RECORDS')

  const statusMap: { [key: string]: StatusIndicatorProps.Type } = {
    UNKNOWN: 'warning',
    SUCCESS: 'success',
    PENDING: 'pending',
    FAILURE: 'error',
    NO_RECORDS: 'success',
  }

  return (
    <Table
      columnDefinitions={[
        {
          id: 'objectModel',
          header: 'Object',
          cell: (item) => item.model,
          sortingField: 'model',
          maxWidth: '12rem',
        },
        {
          id: 'records',
          header: 'Records',
          cell: (item) => item.numberRecords,
          sortingField: 'model',
          minWidth: '4rem',
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => <StatusIcon item={item} mapping={statusMap} />,
          sortingField: 'status',
          minWidth: '4rem',
        },
      ]}
      items={filteredObjectData}
      loadingText="Loading object data"
      trackBy="id"
      visibleColumns={['objectModel', 'records', 'status']}
      variant={variant}
      header={
        <Header
          actions={
            <Checkbox onChange={toggleShowNoRecordObjects} checked={showNoRecordObjects}>
              Show Objects with no records
            </Checkbox>
          }
        >
          Data
        </Header>
      }
      wrapLines
      empty={<EmptyTableMessage title="No object data found" subtitle="" />}
    />
  )
}
