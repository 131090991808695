import { Box, Button, Modal, SpaceBetween, TextContent } from '@cloudscape-design/components'
import { useModalData } from './ModalContext'

export const ConfirmationModalName = 'ConfirmationModal'

export interface ConfirmationModalProps {
  title: string
  body: string
  onConfirm: () => Promise<void>
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { visible, disableModal } = useModalData(ConfirmationModalName)

  const onConfirm = async () => {
    await props.onConfirm()
    disableModal()
  }

  return (
    <Modal
      header={props.title}
      onDismiss={disableModal}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <TextContent>{props.body}</TextContent>
    </Modal>
  )
}
