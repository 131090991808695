import {
  Spinner,
  Box,
  Table,
  Link,
  Button,
  Header,
  SpaceBetween,
  Checkbox,
  TextContent,
  Pagination,
  TextFilter,
} from '@cloudscape-design/components'
import { Template } from 'twirp/api'
import { useState } from 'react'
import { TCCService } from 'services/api'
import { useQuery } from 'react-query'
import { TemplateUploadModal, TemplateUploadModalName } from 'components/TemplateUploadModal'
import { useModalEnabler } from 'components/ModalContext'
import { TemplateBadge } from 'components/TemplateBadge'
import { useCollection } from '@cloudscape-design/collection-hooks'
import { EmptyTableMessage } from 'components/EmptyTableMessage'

export const TemplatesPage = () => {
  const [templates, setTemplates] = useState<Template[]>([])
  const [goldenFilter, setGoldenFilter] = useState(true)
  const [draftFilter, setDraftFilter] = useState(false)

  const { isLoading } = useQuery('getTemplates', async () => {
    const data = await TCCService.GetTemplates({ deployable: false })

    setTemplates(data.templates)
    return data.templates
  })

  let filteredTemplates = templates.filter((t) => t.id !== 'fa6e5a8d-9087-4cd3-80e4-ddb8bc7c2524')

  if (!goldenFilter) {
    filteredTemplates = filteredTemplates.filter((t) => !t.golden)
  }

  if (!draftFilter) {
    filteredTemplates = filteredTemplates.filter((t) => t.published)
  }

  const { enableModal } = useModalEnabler()

  const columnDefinitions = [
    {
      id: 'templateName',
      header: 'Name',
      cell: (t: Template) => (
        <Link variant="secondary" href={`/templates/${t.id}`}>
          {t.name}
        </Link>
      ),
      sortingField: 'name',
      width: '50%',
    },
    {
      id: 'templateCreatedAt',
      header: 'Created At',
      cell: (t: Template) => (
        <TextContent>{`${t.createdAt?.toLocaleDateString()} ${t.createdAt?.toLocaleTimeString()}`}</TextContent>
      ),
      sortingField: 'createdAt',
      width: '30%',
    },
    {
      id: 'templateStatus',
      header: 'Status',
      cell: (t: Template) => <TemplateBadge template={t} />,
      sortingComparator: sortByStatus,
      width: '20%',
    },
  ]

  const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    filteredTemplates,
    {
      filtering: {
        empty: <EmptyTableMessage title="No templates" subtitle="No templates to display." />,
        noMatch: <EmptyTableMessage title="No matches" subtitle="No templates match the filters provided." />,
      },
      pagination: { pageSize: 25 },
      sorting: {
        defaultState: {
          sortingColumn: columnDefinitions[0],
        },
      },
      selection: {},
    }
  )

  if (isLoading) {
    return <Spinner></Spinner>
  }

  return (
    <Box padding="m">
      <Table
        {...collectionProps}
        variant="embedded"
        columnDefinitions={columnDefinitions}
        items={items}
        loading={isLoading}
        loadingText="Loading templates"
        trackBy="id"
        visibleColumns={['templateName', 'templateCreatedAt', 'templateStatus']}
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter templates"
          />
        }
        header={
          <Box>
            <Header actions={<Button onClick={() => enableModal(TemplateUploadModalName)}>Upload</Button>}>
              Templates
            </Header>

            <SpaceBetween size="s" direction="horizontal">
              <Checkbox checked={goldenFilter} onChange={({ detail }) => setGoldenFilter(detail.checked)}>
                Golden
              </Checkbox>
              <Checkbox checked={draftFilter} onChange={({ detail }) => setDraftFilter(detail.checked)}>
                Draft
              </Checkbox>
            </SpaceBetween>
          </Box>
        }
      />
      <TemplateUploadModal />
    </Box>
  )
}

const sortByStatus = (a: Template, b: Template): number => {
  const aVal = 2 * (a.golden ? 1 : 0) + 1 * (a.published ? 1 : 0)
  const bVal = 2 * (b.golden ? 1 : 0) + 1 * (b.published ? 1 : 0)

  if (aVal === bVal) {
    return a.name.localeCompare(b.name)
  }

  return aVal > bVal ? 1 : -1
}

export function getMatchesCountText(count?: number) {
  return count === 1 ? `1 match` : `${count || 0} matches`
}
