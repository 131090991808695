import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { TCCService } from 'services/api'
import { Customer } from 'twirp/api'
import { Table, Link, Button, Header, Box } from '@cloudscape-design/components'

export const Customers = () => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const { isLoading } = useQuery('getCustomers', async () => {
    const data = await TCCService.GetCustomers({})
    const sortedCustomers = data.customers.sort((a: Customer, b: Customer): number => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    setCustomers([...sortedCustomers])
    return data
  })
  const navigate = useNavigate()
  const _onNewCustomerClicked = () => {
    navigate('/customers/new')
  }

  return (
    <Box padding="m">
      <Table
        variant="embedded"
        columnDefinitions={[
          {
            id: 'customerName',
            header: 'Name',
            cell: (c) => (
              <Link variant="secondary" href={`/customers/${c.id}`}>
                {c.name}
              </Link>
            ),
            sortingField: 'name',
          },
        ]}
        items={customers}
        loading={isLoading}
        loadingText="Loading customers"
        trackBy="id"
        visibleColumns={['customerName']}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No customers found.
            </Box>
            <Button href="/customers/new">Register customer</Button>
          </Box>
        }
        header={
          <Header
            actions={
              <Button variant="primary" href="/customers/new" data-testid="new-customer">
                New
              </Button>
            }
          >
            Customers
          </Header>
        }
      />
    </Box>
  )
}
