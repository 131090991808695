import { Header, StatusIndicatorProps, Table, TableProps } from '@cloudscape-design/components'
import { UserProvisioningDeployment } from 'twirp/api'
import { StatusIcon } from './StatusIcon'
import { EmptyTableMessage } from './EmptyTableMessage'
import { CopyOnClickText } from './CopyOnClickText'

export const UserProvisioningDeploymentsTable = ({
  userProvisioningDeployments,
  tableVariant,
  includeHeader,
  isLoading,
}: {
  userProvisioningDeployments: UserProvisioningDeployment[]
  tableVariant?: TableProps.Variant
  includeHeader?: boolean
  isLoading?: boolean
}) => {
  const statusMap: { [key: string]: StatusIndicatorProps.Type } = {
    UNKNOWN: 'warning',
    SUCCESS: 'success',
    FAILURE: 'error',
  }

  const numUsersProvisioned = userProvisioningDeployments.filter((upd) => upd.status == 'SUCCESS').length

  return (
    <Table
      columnDefinitions={[
        {
          id: 'email',
          header: <div>Email ({numUsersProvisioned})</div>,
          cell: (item) => <CopyOnClickText value={item.demoUserEmail} />,
          width: '30rem',
          minWidth: '10rem',
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => <StatusIcon item={item} mapping={statusMap} />,
          minWidth: '2rem',
          maxWidth: '4rem',
        },
        {
          id: 'error',
          header: 'Error Message',
          cell: (item) => item.errorMessage,
          minWidth: '2rem',
          maxWidth: '4rem',
        },
      ]}
      items={userProvisioningDeployments}
      loading={isLoading}
      loadingText="Loading user provisioning logs"
      trackBy="id"
      resizableColumns
      visibleColumns={['email', 'status', 'error']}
      header={includeHeader && <Header>Users</Header>}
      wrapLines
      variant={tableVariant}
      empty={<EmptyTableMessage title="No user provisioning logs found" subtitle="" />}
    />
  )
}
