import { Box, Button, Input, Modal, SpaceBetween, TextContent, Textarea } from '@cloudscape-design/components'
import { useModalData } from './ModalContext'
import { updateTemplate } from 'lib/template'
import { useState } from 'react'
import { Template } from 'twirp/api'

export const TemplateEditModalName = 'TemplateEditModal'

export interface TemplateEditModalProps {
  template: Template
}

export const TemplateEditModal = ({ template }: TemplateEditModalProps) => {
  const { disableModal, visible } = useModalData(TemplateEditModalName)

  const [name, setName] = useState(template.name)
  const [description, setDescription] = useState(template.description)

  const handleUpdateTemplate = async () => {
    await updateTemplate({
      ...template,
      id: template.id,
      name: name,
      description: description,
    })
    disableModal()
    window.location.reload()
  }

  const canUpdate = name !== '' && description !== ''

  return (
    <Modal
      onDismiss={disableModal}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdateTemplate} disabled={!canUpdate}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Update a Template"
    >
      <Box padding="s">
        <Box>
          <TextContent>
            <h5>Template Name</h5>
            <Input inputMode="text" value={name} onChange={({ detail }) => setName(detail.value)} invalid={!name} />
          </TextContent>
        </Box>
        <Box padding={{ top: 's' }}>
          <TextContent>
            <h5>Template Description</h5>
            <Textarea value={description} onChange={({ detail }) => setDescription(detail.value)} />
          </TextContent>
        </Box>
      </Box>
    </Modal>
  )
}
