import { axiosRPC, TCCService } from 'services/api'
import { useNavigate } from 'react-router-dom'
import { TendoCloudConfiguratorServiceClientJSON } from '../twirp/api.twirp-client'
import {
  Button,
  Container,
  Header,
  TextContent,
  Form,
  SpaceBetween,
  FormField,
  Input,
  Box,
  Spinner,
  Autosuggest,
  Select,
  RadioGroup,
  Tiles,
  Alert,
  Link,
  Checkbox,
} from '@cloudscape-design/components'
import { useContext, useState } from 'react'
import { Customer, Template, Tenant } from 'twirp/api'
import { useQuery } from 'react-query'
import { FlashbarContext } from 'components/FlashMessage'
import { EMPTY_TEMPLATE_ID } from 'lib/template'
import { useDBClusterOptions } from 'lib/tenant'
import { TemplateContentModal, TemplateContentModalName } from 'components/TemplateContentModal'
import { useModalEnabler } from 'components/ModalContext'
import { ObjectDataTableModal, ObjectDataTableModalName } from 'components/ObjectDataTableModal'
import { useAuth0 } from '@auth0/auth0-react'

enum TenantType {
  PROD = 'prod',
  NONPROD = 'nonprod',
}

const subdomainRegex = /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

const alphaNumericRegex = /^[a-z0-9]+$/i

const tenantNameRegex = /^[a-z0-9\-_]+$/i

const uppercaseRegex = /^[A-Z]*$/

const findCustomerById = (customers: Customer[], id: string): Customer | null => {
  for (const c of customers) {
    if (c.id === id) {
      return c
    }
  }
  return null
}

const findTenantByName = (tenants: Tenant[], name: string): Tenant | null => {
  for (const t of tenants) {
    if (t.name === name) {
      return t
    }
  }
  return null
}

const findTenantBySubdomain = (tenants: Tenant[], subdomain: string): Tenant | null => {
  const idx = tenants.findIndex((tenant) => tenant.subdomain === subdomain)
  return idx > 0 ? tenants[idx] : null
}

const findTenantTemplateById = (tenantTemplates: Template[], id: string): Template | null => {
  for (const tt of tenantTemplates) {
    if (tt.id === id) {
      return tt
    }
  }
  return null
}

const tenantSubdomainExists = (tenants: Tenant[], subdomain: string): boolean => {
  for (const t of tenants) {
    if (t.subdomain === subdomain) {
      return true
    }
  }
  return false
}

const tenantTemplateAsSelectOption = (tt: Template | null) => {
  if (!tt) {
    return {
      value: '',
      label: '',
    }
  }
  return {
    value: tt.id,
    label: tt.name,
  }
}

// auth0 requires org names to be lowercase alphanumeric with only '-' or '_'
// characters, the first char must be alphanumeric
const validAuth0Name = (c: string): boolean => {
  if (!alphaNumericRegex.test(c.charAt(0)) || !tenantNameRegex.test(c)) {
    return false
  }

  for (let i = 0; i < c.length; i++) {
    if (uppercaseRegex.test(c.charAt(i))) {
      return false
    }
  }

  return true
}

export const NewTenant = () => {
  const { enableModal } = useModalEnabler()
  const { pushMessage } = useContext(FlashbarContext)
  const { user } = useAuth0()
  const [customers, setCustomers] = useState<Customer[]>()
  const [customer, setCustomer] = useState<Customer | null>(null)
  const handleCustomerSelected = (id: string) => {
    setCustomer(findCustomerById(customers || [], id))
  }

  const [tenantType, setTenantType] = useState<TenantType>(TenantType.NONPROD)
  const [tenants, setTenants] = useState<Tenant[]>(new Array<Tenant>())
  const [tenantName, setTenantName] = useState<string>('')

  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)
  const [showDraftTemplates, setShowDraftTemplates] = useState(false)
  const handleTenantTemplateSelected = (id: string) => {
    setSelectedTemplate(findTenantTemplateById(templates, id))
    setIncludeObjectData(false)
    setProvisionDemoUsers(false)
    setProvisionAuth0Users(false)
    setDemoUserBaseEmail('')
  }

  const [subdomainSuffix, setSubdomainSuffix] = useState<string>('')

  const [enableOauth, setEnableOauth] = useState(false)
  const [userFullName, setUserFullName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const handleToggleOauth = (b: boolean) => {
    setEnableOauth(b)
    setUserEmail('')
    setUserFullName('')
  }
  const [includeObjectData, setIncludeObjectData] = useState(false)
  const [provisionDemoUsers, setProvisionDemoUsers] = useState(false)
  const [demoUserBaseEmail, setDemoUserBaseEmail] = useState('')
  const [provisionAuth0Users, setProvisionAuth0Users] = useState(false)

  const navigate = useNavigate()
  const onSubmit = async () => {
    if (!customer) {
      throw new Error('no customer selected')
    } else if (!selectedTemplate) {
      throw new Error('no template selected')
    }

    try {
      const subdomain = tenantType === TenantType.PROD ? customer.shortName : `${customer.shortName}-${subdomainSuffix}`

      const name = tenantType === TenantType.PROD ? `${customer.name} - Production` : `${customer.name} - ${tenantName}`

      const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
      const resp = await client.CreateTenantFromTemplate({
        customerId: customer.id,
        templateId: selectedTemplate.id,
        tenantName: name,
        tenantSubdomain: subdomain,
        firstUser: {
          name: userFullName,
          email: userEmail,
        },
        enableTendoEmployeeAccess: enableOauth,
        isProduction: tenantType === TenantType.PROD,
        includeObjectData: includeObjectData,
        overrideObjectDataIds: [],
        dbCluster: selectedDbCluster,
        provisionDemoUsers: provisionDemoUsers,
        provisionDemoUserAuth0Accounts: provisionAuth0Users,
        demoUserBaseEmail: demoUserBaseEmail,
      })
      navigate(`/customers/${customer.id}/tenants/${resp.tenant!.id}`)
    } catch (e) {
      pushMessage('error', (e as Error).message)
      window.scrollTo(0, 0)
    }
  }

  const { isLoading: customersLoading } = useQuery('getCustomers', async () => {
    const data = await TCCService.GetCustomers({})
    setCustomers(data.customers)

    return data.customers
  })

  const { isLoading: tenantsLoading } = useQuery(`getTenants-${customer?.id}`, async () => {
    let tenants = new Array<Tenant>()
    if (customer) {
      const data = await TCCService.GetTenants({
        customerId: customer.id,
      })
      tenants = data.tenants
    }
    setTenants(tenants)

    return tenants
  })

  const { isLoading: templatesLoading } = useQuery(`getTemplates`, async () => {
    const data = await TCCService.GetTemplates({
      deployable: true,
    })
    setTemplates(data.templates)

    setSelectedTemplate(data.templates[0])

    return data.templates
  })

  const sortedTemplates = templates.sort((a, b) =>
    a.id === EMPTY_TEMPLATE_ID ? -1 : a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
  )

  const filteredTemplates = sortedTemplates.filter((t) => showDraftTemplates || t.published)

  const { dbClusters } = useDBClusterOptions()
  const [selectedDbCluster, setSelectedDbCluster] = useState('default')

  const loading = customersLoading || tenantsLoading
  const prodTenantUsed = !loading && customer !== null && tenantSubdomainExists(tenants, `${customer.shortName}`)

  const validTenantName =
    (tenantName !== '' &&
      findTenantByName(tenants, `${customer!.name} - ${tenantName}`) === null &&
      validAuth0Name(tenantName)) ||
    tenantType === TenantType.PROD

  const validCustomSubdomain =
    (customer !== null &&
      subdomainRegex.test(`${customer.shortName}-${subdomainSuffix}`) &&
      findTenantBySubdomain(tenants, `${customer.shortName}-${subdomainSuffix}`) === null) ||
    tenantType === TenantType.PROD

  const validUserFullName = userFullName !== ''
  const validUserEmail = emailRegex.test(userEmail)
  const validAuthOptions = enableOauth || (validUserEmail && validUserFullName)
  const validDemoUserProvisioningOptions =
    !provisionDemoUsers || (demoUserBaseEmail !== '' && emailRegex.test(demoUserBaseEmail))

  const canSubmit =
    validTenantName &&
    validCustomSubdomain &&
    validAuthOptions &&
    customer !== null &&
    selectedTemplate !== null &&
    validDemoUserProvisioningOptions

  if (customersLoading) {
    return <Spinner></Spinner>
  }

  return (
    <Box padding="m">
      <Form
        variant="embedded"
        header={<Header variant="h1">New Tenant</Header>}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" href="/customers">
              Cancel
            </Button>
            <Button variant="primary" onClick={onSubmit} disabled={!canSubmit}>
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="m">
          <Container header={<Header>Basic Information</Header>}>
            <SpaceBetween size="s">
              <FormField label="Customer" description="Choose a customer to create a tenant for.">
                <Autosuggest
                  onChange={({ detail }) => handleCustomerSelected(detail.value)}
                  value={customer?.name || ''}
                  options={customers?.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  enteredTextLabel={(value) => `Use: "${value}"`}
                  ariaLabel="Choose a customer"
                  placeholder="Choose a customer"
                  empty="No matches found"
                />
              </FormField>
              {customer !== null && !tenantsLoading && (
                <>
                  <FormField>
                    <RadioGroup
                      onChange={({ detail }) => setTenantType(detail.value as TenantType)}
                      value={tenantType}
                      items={[
                        {
                          value: TenantType.PROD,
                          label: !prodTenantUsed ? 'Production' : 'Production (already exists for this customer)',
                          disabled: prodTenantUsed,
                        },
                        {
                          value: TenantType.NONPROD,
                          label: 'Non-production',
                        },
                      ]}
                    />
                  </FormField>
                  {tenantType === TenantType.NONPROD && (
                    <>
                      <FormField
                        label="Tenant Name"
                        description={`A human friendly name for the tenant. Must be lower case and alphanumeric. Can contain '-' or '_'`}
                      >
                        <Input
                          value={tenantName}
                          onChange={({ detail }) => {
                            setTenantName(detail.value)
                            if (findTenantByName(tenants, `${customer!.name} - ${detail.value}`) !== null) {
                              pushMessage('error', `Tenant name: ${detail.value} has already been used.`)
                            }
                          }}
                          invalid={tenantName !== '' && !validTenantName}
                        />
                      </FormField>
                      <FormField
                        label="Subdomain"
                        description={`Can only contain lowercase alphanumerics and dashes. Used in DNS and Auth0 lookups. Will be prefixed with '${
                          customer!.shortName
                        }-'`}
                      >
                        <Input
                          value={subdomainSuffix}
                          onChange={({ detail }) => {
                            setSubdomainSuffix(detail.value)
                            if (findTenantBySubdomain(tenants, `${customer.shortName}-${detail.value}`) != null) {
                              pushMessage('error', `Subdomain: ${detail.value} has already been used.`)
                            }
                          }}
                          invalid={subdomainSuffix !== '' && !validCustomSubdomain}
                        />
                      </FormField>
                    </>
                  )}
                  <FormField
                    label="Tenant Name Preview"
                    description={
                      tenantType === TenantType.PROD
                        ? `${customer.name} - Production`
                        : `${customer.name} - ${tenantName}`
                    }
                  ></FormField>
                  <FormField
                    label="Subdomain Preview"
                    description={
                      tenantType === TenantType.PROD ? customer!.shortName : `${customer.shortName}-${subdomainSuffix}`
                    }
                  ></FormField>
                </>
              )}
              {tenantsLoading && <Spinner />}
            </SpaceBetween>
          </Container>
          <Container
            header={
              <TextContent>
                <Header>Authentication</Header>
                <small>This user will be the bootstrap user who may add additional admins.</small>
              </TextContent>
            }
          >
            <SpaceBetween size="l">
              <Tiles
                onChange={({ detail }) => handleToggleOauth(detail.value === 'true')}
                value={enableOauth ? 'true' : 'false'}
                items={[
                  { label: 'Bootstrap User', value: 'false' },
                  { label: 'Tendo Google OAuth', value: 'true' },
                ]}
              />
              <TextContent>
                <SpaceBetween size="m">
                  <FormField label="Full name">
                    <Input
                      value={userFullName}
                      onChange={({ detail }) => setUserFullName(detail.value)}
                      invalid={userFullName != '' && !validUserFullName}
                      disabled={enableOauth}
                    />
                  </FormField>
                  <FormField label="Email">
                    <Input
                      value={userEmail}
                      onChange={({ detail }) => setUserEmail(detail.value)}
                      invalid={userEmail !== '' && !validUserEmail}
                      disabled={enableOauth}
                    />
                  </FormField>
                </SpaceBetween>
              </TextContent>
              {enableOauth && (
                <Alert>
                  Choosing Tendo Google OAuth allows anyone with an @tendo.com email address to log into this tenant.
                  This should be used only in non-production stages!
                </Alert>
              )}
            </SpaceBetween>
          </Container>

          <Container
            header={
              <TextContent>
                <Header>Database Configuration</Header>
                <small>
                  {`Choose the cluster which Object Service will deploy the
                  tenant's database to.`}
                </small>
              </TextContent>
            }
          >
            <SpaceBetween size="l">
              <FormField label="Cluster">
                <Select
                  selectedOption={{
                    label: selectedDbCluster,
                    value: selectedDbCluster,
                  }}
                  onChange={({ detail }) => setSelectedDbCluster(detail.selectedOption.value!)}
                  options={dbClusters.map((d) => ({ label: d, value: d }))}
                />
              </FormField>
            </SpaceBetween>
          </Container>

          <Container
            header={
              <TextContent>
                <Header>Template Configuration</Header>
              </TextContent>
            }
          >
            {templatesLoading && <Spinner />}
            {!templatesLoading && (
              <TextContent>
                <SpaceBetween size="s">
                  <FormField label="Template">
                    <SpaceBetween size="xl" direction="horizontal">
                      <SpaceBetween size="s">
                        <Select
                          selectedOption={tenantTemplateAsSelectOption(
                            findTenantTemplateById(filteredTemplates, selectedTemplate ? selectedTemplate.id : '')
                          )}
                          options={filteredTemplates.map((tt) => tenantTemplateAsSelectOption(tt))}
                          selectedAriaLabel="Selected"
                          onChange={(event) => handleTenantTemplateSelected(event.detail.selectedOption.value!)}
                        />
                        <Checkbox
                          checked={showDraftTemplates}
                          onChange={(d) => setShowDraftTemplates(d.detail.checked)}
                        >
                          Show deployable draft templates
                        </Checkbox>
                        <TextContent>
                          <small>
                            Only successfully exported templates (all statuses are &apos;Success&apos;) may be chosen.
                          </small>
                        </TextContent>
                      </SpaceBetween>
                      <Box>
                        <Box>
                          <Link onFollow={() => enableModal(TemplateContentModalName)}>Inspect JSON</Link>
                        </Box>
                        {selectedTemplate?.includesObjectData && (
                          <Box>
                            <Link onFollow={() => enableModal(ObjectDataTableModalName)}>Inspect Data Objects</Link>
                          </Box>
                        )}
                      </Box>
                    </SpaceBetween>
                  </FormField>

                  {selectedTemplate && (
                    <>
                      {selectedTemplate.id !== EMPTY_TEMPLATE_ID && (
                        <FormField label="Link" stretch>
                          <Box>
                            <Link href={`/templates/${selectedTemplate.id}`} external>
                              {selectedTemplate.name}
                            </Link>
                          </Box>
                        </FormField>
                      )}

                      <FormField label="Description">
                        <p>{selectedTemplate.description}</p>
                      </FormField>

                      <FormField label="Data">
                        <small>
                          {selectedTemplate.includesObjectData
                            ? 'This template contains object data that will be deployed to the new tenant if included.'
                            : 'This template does not have any object data to deploy.'}
                        </small>
                        <Checkbox
                          checked={includeObjectData}
                          onChange={({ detail }) => {
                            setIncludeObjectData(detail.checked)
                            if (detail.checked && selectedTemplate.emrless) {
                              setProvisionDemoUsers(true)
                              setProvisionAuth0Users(true)
                              setDemoUserBaseEmail(user?.email || '')
                            }
                          }}
                          disabled={!selectedTemplate.includesObjectData}
                        >
                          Include object data
                        </Checkbox>
                      </FormField>

                      {includeObjectData && selectedTemplate.emrless && (
                        <SpaceBetween size="s">
                          <FormField label="Provision Demo Users">
                            <RadioGroup
                              onChange={({ detail }) => {
                                setProvisionDemoUsers(detail.value == 'yes')
                                if (detail.value == 'no') {
                                  setProvisionAuth0Users(false)
                                  setDemoUserBaseEmail('')
                                }
                              }}
                              value={provisionDemoUsers ? 'yes' : 'no'}
                              items={[
                                { value: 'yes', label: 'Yes' },
                                { value: 'no', label: 'No' },
                              ]}
                            />
                          </FormField>
                          <FormField label="Base Demo User Email Address">
                            <small>
                              The email address used as the templatized email/username for auto-provisioned demo users.
                              This address will also receive all invite emails.
                            </small>
                            <Input
                              value={demoUserBaseEmail}
                              onChange={({ detail }) => setDemoUserBaseEmail(detail.value)}
                              invalid={provisionDemoUsers && !emailRegex.test(demoUserBaseEmail)}
                              placeholder="Enter email address..."
                              disabled={!provisionDemoUsers}
                            />
                          </FormField>
                          <Checkbox
                            checked={provisionAuth0Users}
                            onChange={({ detail }) => {
                              setProvisionAuth0Users(detail.checked)
                            }}
                            disabled={!provisionDemoUsers}
                          >
                            Provision Auth0 accounts for all Users
                          </Checkbox>
                        </SpaceBetween>
                      )}

                      <TemplateContentModal templateId={selectedTemplate.id} />
                      <ObjectDataTableModal objectData={selectedTemplate.objectData} />
                    </>
                  )}
                </SpaceBetween>
              </TextContent>
            )}
          </Container>
        </SpaceBetween>
      </Form>
    </Box>
  )
}
