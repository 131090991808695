/* eslint-disable */
import Long from 'long'
import * as _m0 from 'protobufjs/minimal'
import { Timestamp } from './google/protobuf/timestamp'

export const protobufPackage = 'tcc.v1'

export interface GetTenantsRequest {
  /** specify a specific customer to fetch tenants for */
  customerId: string
}

export interface GetTenantsResponse {
  tenants: Tenant[]
}

export interface GetTenantRequest {
  tenantId: string
}

export interface GetTenantResponse {
  tenant: Tenant | undefined
}

export interface Tenant {
  id: string
  name: string
  subdomain: string
  auth0OrgId: string
  /** DEPRECATED */
  serviceStatuses: { [key: string]: string }
  /** DEPRECATED */
  serviceErrorMessages: { [key: string]: string }
  archived: boolean
  createdAt: Date | undefined
  createdFromTemplateId: string
  customerId: string
  production: boolean
  dbCluster: string
  includeObjectDataFromTemplate: boolean
  templateFragmentDeployments: TemplateFragmentDeployment[]
  objectDataDeployments: ObjectDataDeployment[]
}

export interface Tenant_ServiceStatusesEntry {
  key: string
  value: string
}

export interface Tenant_ServiceErrorMessagesEntry {
  key: string
  value: string
}

export interface GetCustomersRequest {}

export interface GetCustomersResponse {
  customers: Customer[]
}

export interface GetCustomerRequest {
  customerId: string
}

export interface GetCustomerResponse {
  customer: Customer | undefined
  tenants: Tenant[]
}

export interface CreateCustomerRequest {
  customer: Customer | undefined
  firstUser: User | undefined
}

export interface CreateCustomerResponse {
  customer: Customer | undefined
  /** The tenants created for the customer as part of the creation process */
  tenants: Tenant[]
  /** Deprecated */
  firstUser: User | undefined
}

export interface Customer {
  id: string
  name: string
  /**
   * The shortened name for the customer. Used to generate subdomains:
   * e.g. the customer may be "Foobar Inc.", and the short_name is foobar,
   * so domains would be foobar.<...>, foobar-test.<...>, foobar-dev.<...>, etc
   *
   * This field should ideally be 10ish or fewer alphabetical characters
   */
  shortName: string
  accountNumber: string
}

export interface User {
  name: string
  email: string
}

export interface GetTenantTemplatesRequest {}

export interface GetTenantTemplatesResponse {
  tenantTemplates: TenantTemplate[]
}

export interface TenantTemplate {
  id: string
  name: string
  configuration: string
  description: string
  golden: boolean
  archived: boolean
}

export interface CreateTenantFromTemplateRequest {
  customerId: string
  templateId: string
  tenantName: string
  tenantSubdomain: string
  /**
   * A bootstrap user to associate allow to perform initial login
   * Will be nil if the `enable_tendo_google_oauth` is true.
   */
  firstUser: User | undefined
  /**
   * If true, will configure the tenant to allow login via Tendo's
   * Google OAuth connection. This should only be used in non-production
   * stages.
   */
  enableTendoEmployeeAccess: boolean
  /**
   * Whether to signal within TCC that this is a production tenant.
   * Production tenants may have special properties and be handled differently
   */
  isProduction: boolean
  /**
   * If set, will deploy the template along with associated data. If the template
   * has no associated data, setting this to true has no effect.
   */
  includeObjectData: boolean
  /**
   * If set, will use these object data sets instead of whatever is associated
   * with the template. If include_object_data is false, this field has no effect.
   */
  overrideObjectDataIds: string[]
  /**
   * The database cluster into which object service should provision a database
   * for the tenant
   */
  dbCluster: string
  /**
   * If set, will provision demo users using the object data included with the
   * template
   */
  provisionDemoUsers: boolean
  /** The base email address of the user who will be receive the demo user emails */
  demoUserBaseEmail: string
  /**
   * If set, will create auth0 accounts for each Person email in the template's
   * object data, requires demo_user_base_email to be set
   */
  provisionDemoUserAuth0Accounts: boolean
}

export interface CreateTenantFromTemplateResponse {
  tenant: Tenant | undefined
}

export interface DestroyTenantRequest {
  tenantId: string
}

export interface DestroyTenantResponse {
  tenant: Tenant | undefined
}

export interface DeleteTenantRequest {
  tenantId: string
  force: boolean
}

export interface DeleteTenantResponse {
  tenant: Tenant | undefined
}

export interface InitiateTenantExportRequest {
  tenantId: string
  templateName: string
  templateDescription: string
  includeObjectData: boolean
}

export interface InitiateTenantExportResponse {
  templateId: string
}

export interface GetTenantDestructionsRequest {
  tenantId: string
}

export interface GetTenantDestructionsResponse {
  tenantDestructions: TenantDestruction[]
}

export interface TenantDestruction {
  service: string
  status: string
  errorMessage: string
  name: string
}

export interface GetTemplateFragmentDeploymentsRequest {
  tenantId: string
}

export interface GetTemplateFragmentDeploymentsResponse {
  templateFragmentDeployments: TemplateFragmentDeployment[]
}

export interface TemplateFragmentDeployment {
  service: string
  name: string
  status: string
  errorMessage: string
}

export interface GetRenderedTemplateRequest {
  templateId: string
}

export interface GetRenderedTemplateResponse {
  templateData: Uint8Array
}

export interface GetRenderedTemplateFragmentRequest {
  templateFragmentId: string
}

export interface GetRenderedTemplateFragmentResponse {
  templateFragmentData: Uint8Array
}

export interface UploadTemplateRequest {
  templateData: Uint8Array
}

export interface UploadTemplateResponse {
  templateId: string
}

export interface GetTemplatesRequest {
  deployable: boolean
}

export interface GetTemplatesResponse {
  templates: Template[]
}

export interface GetTemplateRequest {
  templateId: string
}

export interface GetTemplateResponse {
  template: Template | undefined
}

export interface Template {
  id: string
  name: string
  description: string
  templateFragments: TemplateFragment[]
  golden: boolean
  archived: boolean
  published: boolean
  createdAt: Date | undefined
  createdFromTenantId: string
  includesObjectData: boolean
  /**
   * Contains a list of object data sets associated with the template.
   * If `includes_object_data` is false, this will be nil.
   */
  objectData: ObjectData[]
  emrless: boolean
}

export interface TemplateFragment {
  id: string
  name: string
  service: string
  status: string
  errorMessage: string
  createdAt: Date | undefined
  updatedAt: Date | undefined
}

export interface UpdateTemplateGoldenStatusRequest {
  templateId: string
  golden: boolean
}

export interface UpdateTemplateGoldenStatusResponse {
  templateId: string
  golden: boolean
}

export interface UpdateTemplatePublishedStatusRequest {
  templateId: string
  published: boolean
}

export interface UpdateTemplatePublishedStatusResponse {
  templateId: string
  published: boolean
}

export interface UpdateTemplateEmrlessStatusRequest {
  templateId: string
  emrless: boolean
}

export interface UpdateTemplateEmrlessStatusResponse {
  templateId: string
  emrless: boolean
}

export interface UpdateTemplateRequest {
  templateId: string
  name: string
  description: string
}

export interface UpdateTemplateResponse {
  templateId: string
  name: string
  description: string
}

export interface DeleteTemplateRequest {
  templateId: string
}

export interface DeleteTemplateResponse {
  templateId: string
  name: string
  description: string
  golden: boolean
  archived: boolean
}

export interface ObjectData {
  id: string
  model: string
  numberRecords: number
  status: string
  createdAt: Date | undefined
  updatedAt: Date | undefined
}

export interface UploadObjectDataRequest {
  templateId: string
  data: Uint8Array
}

export interface UploadObjectDataResponse {
  objectData: ObjectData[]
}

export interface ListObjectDBClustersRequest {}

export interface ListObjectDBClustersResponse {
  dbClusters: string[]
}

export interface GetObjectDataRequest {
  templateId: string
}

export interface GetObjectDataResponse {
  objectData: ObjectData[]
}

export interface GetObjectDataPackageRequest {
  templateId: string
}

export interface GetObjectDataPackageResponse {
  downloadUrl: string
}

export interface ObjectDataDeployment {
  id: string
  model: string
  numberRecords: number
  status: string
  createdAt: Date | undefined
  updatedAt: Date | undefined
}

export interface GetObjectDataDeploymentsRequest {
  tenantId: string
}

export interface GetObjectDataDeploymentsResponse {
  objectDataDeployments: ObjectDataDeployment[]
}

export interface DeployObjectDataRequest {
  tenantId: string
  objectDataIds: string[]
}

export interface DeployObjectDataResponse {
  objectDataDeployments: ObjectDataDeployment[]
}

export interface UserProvisioningDeployment {
  id: string
  status: string
  errorMessage: string
  executionId: string
  demoUserEmail: string
}

export interface GetUserProvisioningDeploymentsRequest {
  tenantId: string
}

export interface GetUserProvisioningDeploymentsResponse {
  userProvisioningDeployments: UserProvisioningDeployment[]
}

function createBaseGetTenantsRequest(): GetTenantsRequest {
  return { customerId: '' }
}

export const GetTenantsRequest = {
  encode(message: GetTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== '') {
      writer.uint32(10).string(message.customerId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantsRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.customerId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTenantsRequest {
    return {
      customerId: isSet(object.customerId) ? String(object.customerId) : '',
    }
  },

  toJSON(message: GetTenantsRequest): unknown {
    const obj: any = {}
    message.customerId !== undefined && (obj.customerId = message.customerId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantsRequest>, I>>(object: I): GetTenantsRequest {
    const message = createBaseGetTenantsRequest()
    message.customerId = object.customerId ?? ''
    return message
  },
}

function createBaseGetTenantsResponse(): GetTenantsResponse {
  return { tenants: [] }
}

export const GetTenantsResponse = {
  encode(message: GetTenantsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantsResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenants.push(Tenant.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTenantsResponse {
    return {
      tenants: Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    }
  },

  toJSON(message: GetTenantsResponse): unknown {
    const obj: any = {}
    if (message.tenants) {
      obj.tenants = message.tenants.map((e) => (e ? Tenant.toJSON(e) : undefined))
    } else {
      obj.tenants = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantsResponse>, I>>(object: I): GetTenantsResponse {
    const message = createBaseGetTenantsResponse()
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || []
    return message
  },
}

function createBaseGetTenantRequest(): GetTenantRequest {
  return { tenantId: '' }
}

export const GetTenantRequest = {
  encode(message: GetTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTenantRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
    }
  },

  toJSON(message: GetTenantRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantRequest>, I>>(object: I): GetTenantRequest {
    const message = createBaseGetTenantRequest()
    message.tenantId = object.tenantId ?? ''
    return message
  },
}

function createBaseGetTenantResponse(): GetTenantResponse {
  return { tenant: undefined }
}

export const GetTenantResponse = {
  encode(message: GetTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenant = Tenant.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTenantResponse {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
    }
  },

  toJSON(message: GetTenantResponse): unknown {
    const obj: any = {}
    message.tenant !== undefined && (obj.tenant = message.tenant ? Tenant.toJSON(message.tenant) : undefined)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantResponse>, I>>(object: I): GetTenantResponse {
    const message = createBaseGetTenantResponse()
    message.tenant =
      object.tenant !== undefined && object.tenant !== null ? Tenant.fromPartial(object.tenant) : undefined
    return message
  },
}

function createBaseTenant(): Tenant {
  return {
    id: '',
    name: '',
    subdomain: '',
    auth0OrgId: '',
    serviceStatuses: {},
    serviceErrorMessages: {},
    archived: false,
    createdAt: undefined,
    createdFromTemplateId: '',
    customerId: '',
    production: false,
    dbCluster: '',
    includeObjectDataFromTemplate: false,
    templateFragmentDeployments: [],
    objectDataDeployments: [],
  }
}

export const Tenant = {
  encode(message: Tenant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.subdomain !== '') {
      writer.uint32(26).string(message.subdomain)
    }
    if (message.auth0OrgId !== '') {
      writer.uint32(34).string(message.auth0OrgId)
    }
    Object.entries(message.serviceStatuses).forEach(([key, value]) => {
      Tenant_ServiceStatusesEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).ldelim()
    })
    Object.entries(message.serviceErrorMessages).forEach(([key, value]) => {
      Tenant_ServiceErrorMessagesEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim()
    })
    if (message.archived === true) {
      writer.uint32(56).bool(message.archived)
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(66).fork()).ldelim()
    }
    if (message.createdFromTemplateId !== '') {
      writer.uint32(74).string(message.createdFromTemplateId)
    }
    if (message.customerId !== '') {
      writer.uint32(82).string(message.customerId)
    }
    if (message.production === true) {
      writer.uint32(88).bool(message.production)
    }
    if (message.dbCluster !== '') {
      writer.uint32(98).string(message.dbCluster)
    }
    if (message.includeObjectDataFromTemplate === true) {
      writer.uint32(104).bool(message.includeObjectDataFromTemplate)
    }
    for (const v of message.templateFragmentDeployments) {
      TemplateFragmentDeployment.encode(v!, writer.uint32(114).fork()).ldelim()
    }
    for (const v of message.objectDataDeployments) {
      ObjectDataDeployment.encode(v!, writer.uint32(122).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tenant {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTenant()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.subdomain = reader.string()
          break
        case 4:
          message.auth0OrgId = reader.string()
          break
        case 5:
          const entry5 = Tenant_ServiceStatusesEntry.decode(reader, reader.uint32())
          if (entry5.value !== undefined) {
            message.serviceStatuses[entry5.key] = entry5.value
          }
          break
        case 6:
          const entry6 = Tenant_ServiceErrorMessagesEntry.decode(reader, reader.uint32())
          if (entry6.value !== undefined) {
            message.serviceErrorMessages[entry6.key] = entry6.value
          }
          break
        case 7:
          message.archived = reader.bool()
          break
        case 8:
          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        case 9:
          message.createdFromTemplateId = reader.string()
          break
        case 10:
          message.customerId = reader.string()
          break
        case 11:
          message.production = reader.bool()
          break
        case 12:
          message.dbCluster = reader.string()
          break
        case 13:
          message.includeObjectDataFromTemplate = reader.bool()
          break
        case 14:
          message.templateFragmentDeployments.push(TemplateFragmentDeployment.decode(reader, reader.uint32()))
          break
        case 15:
          message.objectDataDeployments.push(ObjectDataDeployment.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Tenant {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      subdomain: isSet(object.subdomain) ? String(object.subdomain) : '',
      auth0OrgId: isSet(object.auth0OrgId) ? String(object.auth0OrgId) : '',
      serviceStatuses: isObject(object.serviceStatuses)
        ? Object.entries(object.serviceStatuses).reduce<{
            [key: string]: string
          }>((acc, [key, value]) => {
            acc[key] = String(value)
            return acc
          }, {})
        : {},
      serviceErrorMessages: isObject(object.serviceErrorMessages)
        ? Object.entries(object.serviceErrorMessages).reduce<{
            [key: string]: string
          }>((acc, [key, value]) => {
            acc[key] = String(value)
            return acc
          }, {})
        : {},
      archived: isSet(object.archived) ? Boolean(object.archived) : false,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      createdFromTemplateId: isSet(object.createdFromTemplateId) ? String(object.createdFromTemplateId) : '',
      customerId: isSet(object.customerId) ? String(object.customerId) : '',
      production: isSet(object.production) ? Boolean(object.production) : false,
      dbCluster: isSet(object.dbCluster) ? String(object.dbCluster) : '',
      includeObjectDataFromTemplate: isSet(object.includeObjectDataFromTemplate)
        ? Boolean(object.includeObjectDataFromTemplate)
        : false,
      templateFragmentDeployments: Array.isArray(object?.templateFragmentDeployments)
        ? object.templateFragmentDeployments.map((e: any) => TemplateFragmentDeployment.fromJSON(e))
        : [],
      objectDataDeployments: Array.isArray(object?.objectDataDeployments)
        ? object.objectDataDeployments.map((e: any) => ObjectDataDeployment.fromJSON(e))
        : [],
    }
  },

  toJSON(message: Tenant): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.name !== undefined && (obj.name = message.name)
    message.subdomain !== undefined && (obj.subdomain = message.subdomain)
    message.auth0OrgId !== undefined && (obj.auth0OrgId = message.auth0OrgId)
    obj.serviceStatuses = {}
    if (message.serviceStatuses) {
      Object.entries(message.serviceStatuses).forEach(([k, v]) => {
        obj.serviceStatuses[k] = v
      })
    }
    obj.serviceErrorMessages = {}
    if (message.serviceErrorMessages) {
      Object.entries(message.serviceErrorMessages).forEach(([k, v]) => {
        obj.serviceErrorMessages[k] = v
      })
    }
    message.archived !== undefined && (obj.archived = message.archived)
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString())
    message.createdFromTemplateId !== undefined && (obj.createdFromTemplateId = message.createdFromTemplateId)
    message.customerId !== undefined && (obj.customerId = message.customerId)
    message.production !== undefined && (obj.production = message.production)
    message.dbCluster !== undefined && (obj.dbCluster = message.dbCluster)
    message.includeObjectDataFromTemplate !== undefined &&
      (obj.includeObjectDataFromTemplate = message.includeObjectDataFromTemplate)
    if (message.templateFragmentDeployments) {
      obj.templateFragmentDeployments = message.templateFragmentDeployments.map((e) =>
        e ? TemplateFragmentDeployment.toJSON(e) : undefined
      )
    } else {
      obj.templateFragmentDeployments = []
    }
    if (message.objectDataDeployments) {
      obj.objectDataDeployments = message.objectDataDeployments.map((e) =>
        e ? ObjectDataDeployment.toJSON(e) : undefined
      )
    } else {
      obj.objectDataDeployments = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<Tenant>, I>>(object: I): Tenant {
    const message = createBaseTenant()
    message.id = object.id ?? ''
    message.name = object.name ?? ''
    message.subdomain = object.subdomain ?? ''
    message.auth0OrgId = object.auth0OrgId ?? ''
    message.serviceStatuses = Object.entries(object.serviceStatuses ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value)
        }
        return acc
      },
      {}
    )
    message.serviceErrorMessages = Object.entries(object.serviceErrorMessages ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value)
        }
        return acc
      },
      {}
    )
    message.archived = object.archived ?? false
    message.createdAt = object.createdAt ?? undefined
    message.createdFromTemplateId = object.createdFromTemplateId ?? ''
    message.customerId = object.customerId ?? ''
    message.production = object.production ?? false
    message.dbCluster = object.dbCluster ?? ''
    message.includeObjectDataFromTemplate = object.includeObjectDataFromTemplate ?? false
    message.templateFragmentDeployments =
      object.templateFragmentDeployments?.map((e) => TemplateFragmentDeployment.fromPartial(e)) || []
    message.objectDataDeployments = object.objectDataDeployments?.map((e) => ObjectDataDeployment.fromPartial(e)) || []
    return message
  },
}

function createBaseTenant_ServiceStatusesEntry(): Tenant_ServiceStatusesEntry {
  return { key: '', value: '' }
}

export const Tenant_ServiceStatusesEntry = {
  encode(message: Tenant_ServiceStatusesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tenant_ServiceStatusesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTenant_ServiceStatusesEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string()
          break
        case 2:
          message.value = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Tenant_ServiceStatusesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    }
  },

  toJSON(message: Tenant_ServiceStatusesEntry): unknown {
    const obj: any = {}
    message.key !== undefined && (obj.key = message.key)
    message.value !== undefined && (obj.value = message.value)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<Tenant_ServiceStatusesEntry>, I>>(object: I): Tenant_ServiceStatusesEntry {
    const message = createBaseTenant_ServiceStatusesEntry()
    message.key = object.key ?? ''
    message.value = object.value ?? ''
    return message
  },
}

function createBaseTenant_ServiceErrorMessagesEntry(): Tenant_ServiceErrorMessagesEntry {
  return { key: '', value: '' }
}

export const Tenant_ServiceErrorMessagesEntry = {
  encode(message: Tenant_ServiceErrorMessagesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tenant_ServiceErrorMessagesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTenant_ServiceErrorMessagesEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string()
          break
        case 2:
          message.value = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Tenant_ServiceErrorMessagesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    }
  },

  toJSON(message: Tenant_ServiceErrorMessagesEntry): unknown {
    const obj: any = {}
    message.key !== undefined && (obj.key = message.key)
    message.value !== undefined && (obj.value = message.value)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<Tenant_ServiceErrorMessagesEntry>, I>>(
    object: I
  ): Tenant_ServiceErrorMessagesEntry {
    const message = createBaseTenant_ServiceErrorMessagesEntry()
    message.key = object.key ?? ''
    message.value = object.value ?? ''
    return message
  },
}

function createBaseGetCustomersRequest(): GetCustomersRequest {
  return {}
}

export const GetCustomersRequest = {
  encode(_: GetCustomersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetCustomersRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(_: any): GetCustomersRequest {
    return {}
  },

  toJSON(_: GetCustomersRequest): unknown {
    const obj: any = {}
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetCustomersRequest>, I>>(_: I): GetCustomersRequest {
    const message = createBaseGetCustomersRequest()
    return message
  },
}

function createBaseGetCustomersResponse(): GetCustomersResponse {
  return { customers: [] }
}

export const GetCustomersResponse = {
  encode(message: GetCustomersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.customers) {
      Customer.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomersResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetCustomersResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.customers.push(Customer.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetCustomersResponse {
    return {
      customers: Array.isArray(object?.customers) ? object.customers.map((e: any) => Customer.fromJSON(e)) : [],
    }
  },

  toJSON(message: GetCustomersResponse): unknown {
    const obj: any = {}
    if (message.customers) {
      obj.customers = message.customers.map((e) => (e ? Customer.toJSON(e) : undefined))
    } else {
      obj.customers = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetCustomersResponse>, I>>(object: I): GetCustomersResponse {
    const message = createBaseGetCustomersResponse()
    message.customers = object.customers?.map((e) => Customer.fromPartial(e)) || []
    return message
  },
}

function createBaseGetCustomerRequest(): GetCustomerRequest {
  return { customerId: '' }
}

export const GetCustomerRequest = {
  encode(message: GetCustomerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== '') {
      writer.uint32(10).string(message.customerId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomerRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetCustomerRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.customerId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetCustomerRequest {
    return {
      customerId: isSet(object.customerId) ? String(object.customerId) : '',
    }
  },

  toJSON(message: GetCustomerRequest): unknown {
    const obj: any = {}
    message.customerId !== undefined && (obj.customerId = message.customerId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetCustomerRequest>, I>>(object: I): GetCustomerRequest {
    const message = createBaseGetCustomerRequest()
    message.customerId = object.customerId ?? ''
    return message
  },
}

function createBaseGetCustomerResponse(): GetCustomerResponse {
  return { customer: undefined, tenants: [] }
}

export const GetCustomerResponse = {
  encode(message: GetCustomerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customer !== undefined) {
      Customer.encode(message.customer, writer.uint32(10).fork()).ldelim()
    }
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomerResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetCustomerResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.customer = Customer.decode(reader, reader.uint32())
          break
        case 2:
          message.tenants.push(Tenant.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetCustomerResponse {
    return {
      customer: isSet(object.customer) ? Customer.fromJSON(object.customer) : undefined,
      tenants: Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    }
  },

  toJSON(message: GetCustomerResponse): unknown {
    const obj: any = {}
    message.customer !== undefined && (obj.customer = message.customer ? Customer.toJSON(message.customer) : undefined)
    if (message.tenants) {
      obj.tenants = message.tenants.map((e) => (e ? Tenant.toJSON(e) : undefined))
    } else {
      obj.tenants = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetCustomerResponse>, I>>(object: I): GetCustomerResponse {
    const message = createBaseGetCustomerResponse()
    message.customer =
      object.customer !== undefined && object.customer !== null ? Customer.fromPartial(object.customer) : undefined
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || []
    return message
  },
}

function createBaseCreateCustomerRequest(): CreateCustomerRequest {
  return { customer: undefined, firstUser: undefined }
}

export const CreateCustomerRequest = {
  encode(message: CreateCustomerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customer !== undefined) {
      Customer.encode(message.customer, writer.uint32(10).fork()).ldelim()
    }
    if (message.firstUser !== undefined) {
      User.encode(message.firstUser, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCustomerRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseCreateCustomerRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.customer = Customer.decode(reader, reader.uint32())
          break
        case 2:
          message.firstUser = User.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): CreateCustomerRequest {
    return {
      customer: isSet(object.customer) ? Customer.fromJSON(object.customer) : undefined,
      firstUser: isSet(object.firstUser) ? User.fromJSON(object.firstUser) : undefined,
    }
  },

  toJSON(message: CreateCustomerRequest): unknown {
    const obj: any = {}
    message.customer !== undefined && (obj.customer = message.customer ? Customer.toJSON(message.customer) : undefined)
    message.firstUser !== undefined && (obj.firstUser = message.firstUser ? User.toJSON(message.firstUser) : undefined)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<CreateCustomerRequest>, I>>(object: I): CreateCustomerRequest {
    const message = createBaseCreateCustomerRequest()
    message.customer =
      object.customer !== undefined && object.customer !== null ? Customer.fromPartial(object.customer) : undefined
    message.firstUser =
      object.firstUser !== undefined && object.firstUser !== null ? User.fromPartial(object.firstUser) : undefined
    return message
  },
}

function createBaseCreateCustomerResponse(): CreateCustomerResponse {
  return { customer: undefined, tenants: [], firstUser: undefined }
}

export const CreateCustomerResponse = {
  encode(message: CreateCustomerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customer !== undefined) {
      Customer.encode(message.customer, writer.uint32(10).fork()).ldelim()
    }
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(18).fork()).ldelim()
    }
    if (message.firstUser !== undefined) {
      User.encode(message.firstUser, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCustomerResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseCreateCustomerResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.customer = Customer.decode(reader, reader.uint32())
          break
        case 2:
          message.tenants.push(Tenant.decode(reader, reader.uint32()))
          break
        case 3:
          message.firstUser = User.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): CreateCustomerResponse {
    return {
      customer: isSet(object.customer) ? Customer.fromJSON(object.customer) : undefined,
      tenants: Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
      firstUser: isSet(object.firstUser) ? User.fromJSON(object.firstUser) : undefined,
    }
  },

  toJSON(message: CreateCustomerResponse): unknown {
    const obj: any = {}
    message.customer !== undefined && (obj.customer = message.customer ? Customer.toJSON(message.customer) : undefined)
    if (message.tenants) {
      obj.tenants = message.tenants.map((e) => (e ? Tenant.toJSON(e) : undefined))
    } else {
      obj.tenants = []
    }
    message.firstUser !== undefined && (obj.firstUser = message.firstUser ? User.toJSON(message.firstUser) : undefined)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<CreateCustomerResponse>, I>>(object: I): CreateCustomerResponse {
    const message = createBaseCreateCustomerResponse()
    message.customer =
      object.customer !== undefined && object.customer !== null ? Customer.fromPartial(object.customer) : undefined
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || []
    message.firstUser =
      object.firstUser !== undefined && object.firstUser !== null ? User.fromPartial(object.firstUser) : undefined
    return message
  },
}

function createBaseCustomer(): Customer {
  return { id: '', name: '', shortName: '', accountNumber: '' }
}

export const Customer = {
  encode(message: Customer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.shortName !== '') {
      writer.uint32(26).string(message.shortName)
    }
    if (message.accountNumber !== '') {
      writer.uint32(34).string(message.accountNumber)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Customer {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseCustomer()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.shortName = reader.string()
          break
        case 4:
          message.accountNumber = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Customer {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      shortName: isSet(object.shortName) ? String(object.shortName) : '',
      accountNumber: isSet(object.accountNumber) ? String(object.accountNumber) : '',
    }
  },

  toJSON(message: Customer): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.name !== undefined && (obj.name = message.name)
    message.shortName !== undefined && (obj.shortName = message.shortName)
    message.accountNumber !== undefined && (obj.accountNumber = message.accountNumber)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<Customer>, I>>(object: I): Customer {
    const message = createBaseCustomer()
    message.id = object.id ?? ''
    message.name = object.name ?? ''
    message.shortName = object.shortName ?? ''
    message.accountNumber = object.accountNumber ?? ''
    return message
  },
}

function createBaseUser(): User {
  return { name: '', email: '' }
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name)
    }
    if (message.email !== '') {
      writer.uint32(18).string(message.email)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUser()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string()
          break
        case 2:
          message.email = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): User {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      email: isSet(object.email) ? String(object.email) : '',
    }
  },

  toJSON(message: User): unknown {
    const obj: any = {}
    message.name !== undefined && (obj.name = message.name)
    message.email !== undefined && (obj.email = message.email)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser()
    message.name = object.name ?? ''
    message.email = object.email ?? ''
    return message
  },
}

function createBaseGetTenantTemplatesRequest(): GetTenantTemplatesRequest {
  return {}
}

export const GetTenantTemplatesRequest = {
  encode(_: GetTenantTemplatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantTemplatesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantTemplatesRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(_: any): GetTenantTemplatesRequest {
    return {}
  },

  toJSON(_: GetTenantTemplatesRequest): unknown {
    const obj: any = {}
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantTemplatesRequest>, I>>(_: I): GetTenantTemplatesRequest {
    const message = createBaseGetTenantTemplatesRequest()
    return message
  },
}

function createBaseGetTenantTemplatesResponse(): GetTenantTemplatesResponse {
  return { tenantTemplates: [] }
}

export const GetTenantTemplatesResponse = {
  encode(message: GetTenantTemplatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenantTemplates) {
      TenantTemplate.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantTemplatesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantTemplatesResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantTemplates.push(TenantTemplate.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTenantTemplatesResponse {
    return {
      tenantTemplates: Array.isArray(object?.tenantTemplates)
        ? object.tenantTemplates.map((e: any) => TenantTemplate.fromJSON(e))
        : [],
    }
  },

  toJSON(message: GetTenantTemplatesResponse): unknown {
    const obj: any = {}
    if (message.tenantTemplates) {
      obj.tenantTemplates = message.tenantTemplates.map((e) => (e ? TenantTemplate.toJSON(e) : undefined))
    } else {
      obj.tenantTemplates = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantTemplatesResponse>, I>>(object: I): GetTenantTemplatesResponse {
    const message = createBaseGetTenantTemplatesResponse()
    message.tenantTemplates = object.tenantTemplates?.map((e) => TenantTemplate.fromPartial(e)) || []
    return message
  },
}

function createBaseTenantTemplate(): TenantTemplate {
  return {
    id: '',
    name: '',
    configuration: '',
    description: '',
    golden: false,
    archived: false,
  }
}

export const TenantTemplate = {
  encode(message: TenantTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.configuration !== '') {
      writer.uint32(26).string(message.configuration)
    }
    if (message.description !== '') {
      writer.uint32(34).string(message.description)
    }
    if (message.golden === true) {
      writer.uint32(40).bool(message.golden)
    }
    if (message.archived === true) {
      writer.uint32(48).bool(message.archived)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantTemplate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTenantTemplate()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.configuration = reader.string()
          break
        case 4:
          message.description = reader.string()
          break
        case 5:
          message.golden = reader.bool()
          break
        case 6:
          message.archived = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): TenantTemplate {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      configuration: isSet(object.configuration) ? String(object.configuration) : '',
      description: isSet(object.description) ? String(object.description) : '',
      golden: isSet(object.golden) ? Boolean(object.golden) : false,
      archived: isSet(object.archived) ? Boolean(object.archived) : false,
    }
  },

  toJSON(message: TenantTemplate): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.name !== undefined && (obj.name = message.name)
    message.configuration !== undefined && (obj.configuration = message.configuration)
    message.description !== undefined && (obj.description = message.description)
    message.golden !== undefined && (obj.golden = message.golden)
    message.archived !== undefined && (obj.archived = message.archived)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<TenantTemplate>, I>>(object: I): TenantTemplate {
    const message = createBaseTenantTemplate()
    message.id = object.id ?? ''
    message.name = object.name ?? ''
    message.configuration = object.configuration ?? ''
    message.description = object.description ?? ''
    message.golden = object.golden ?? false
    message.archived = object.archived ?? false
    return message
  },
}

function createBaseCreateTenantFromTemplateRequest(): CreateTenantFromTemplateRequest {
  return {
    customerId: '',
    templateId: '',
    tenantName: '',
    tenantSubdomain: '',
    firstUser: undefined,
    enableTendoEmployeeAccess: false,
    isProduction: false,
    includeObjectData: false,
    overrideObjectDataIds: [],
    dbCluster: '',
    provisionDemoUsers: false,
    demoUserBaseEmail: '',
    provisionDemoUserAuth0Accounts: false,
  }
}

export const CreateTenantFromTemplateRequest = {
  encode(message: CreateTenantFromTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== '') {
      writer.uint32(10).string(message.customerId)
    }
    if (message.templateId !== '') {
      writer.uint32(18).string(message.templateId)
    }
    if (message.tenantName !== '') {
      writer.uint32(26).string(message.tenantName)
    }
    if (message.tenantSubdomain !== '') {
      writer.uint32(34).string(message.tenantSubdomain)
    }
    if (message.firstUser !== undefined) {
      User.encode(message.firstUser, writer.uint32(42).fork()).ldelim()
    }
    if (message.enableTendoEmployeeAccess === true) {
      writer.uint32(48).bool(message.enableTendoEmployeeAccess)
    }
    if (message.isProduction === true) {
      writer.uint32(56).bool(message.isProduction)
    }
    if (message.includeObjectData === true) {
      writer.uint32(64).bool(message.includeObjectData)
    }
    for (const v of message.overrideObjectDataIds) {
      writer.uint32(74).string(v!)
    }
    if (message.dbCluster !== '') {
      writer.uint32(82).string(message.dbCluster)
    }
    if (message.provisionDemoUsers === true) {
      writer.uint32(88).bool(message.provisionDemoUsers)
    }
    if (message.demoUserBaseEmail !== '') {
      writer.uint32(98).string(message.demoUserBaseEmail)
    }
    if (message.provisionDemoUserAuth0Accounts === true) {
      writer.uint32(104).bool(message.provisionDemoUserAuth0Accounts)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTenantFromTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseCreateTenantFromTemplateRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.customerId = reader.string()
          break
        case 2:
          message.templateId = reader.string()
          break
        case 3:
          message.tenantName = reader.string()
          break
        case 4:
          message.tenantSubdomain = reader.string()
          break
        case 5:
          message.firstUser = User.decode(reader, reader.uint32())
          break
        case 6:
          message.enableTendoEmployeeAccess = reader.bool()
          break
        case 7:
          message.isProduction = reader.bool()
          break
        case 8:
          message.includeObjectData = reader.bool()
          break
        case 9:
          message.overrideObjectDataIds.push(reader.string())
          break
        case 10:
          message.dbCluster = reader.string()
          break
        case 11:
          message.provisionDemoUsers = reader.bool()
          break
        case 12:
          message.demoUserBaseEmail = reader.string()
          break
        case 13:
          message.provisionDemoUserAuth0Accounts = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): CreateTenantFromTemplateRequest {
    return {
      customerId: isSet(object.customerId) ? String(object.customerId) : '',
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      tenantName: isSet(object.tenantName) ? String(object.tenantName) : '',
      tenantSubdomain: isSet(object.tenantSubdomain) ? String(object.tenantSubdomain) : '',
      firstUser: isSet(object.firstUser) ? User.fromJSON(object.firstUser) : undefined,
      enableTendoEmployeeAccess: isSet(object.enableTendoEmployeeAccess)
        ? Boolean(object.enableTendoEmployeeAccess)
        : false,
      isProduction: isSet(object.isProduction) ? Boolean(object.isProduction) : false,
      includeObjectData: isSet(object.includeObjectData) ? Boolean(object.includeObjectData) : false,
      overrideObjectDataIds: Array.isArray(object?.overrideObjectDataIds)
        ? object.overrideObjectDataIds.map((e: any) => String(e))
        : [],
      dbCluster: isSet(object.dbCluster) ? String(object.dbCluster) : '',
      provisionDemoUsers: isSet(object.provisionDemoUsers) ? Boolean(object.provisionDemoUsers) : false,
      demoUserBaseEmail: isSet(object.demoUserBaseEmail) ? String(object.demoUserBaseEmail) : '',
      provisionDemoUserAuth0Accounts: isSet(object.provisionDemoUserAuth0Accounts)
        ? Boolean(object.provisionDemoUserAuth0Accounts)
        : false,
    }
  },

  toJSON(message: CreateTenantFromTemplateRequest): unknown {
    const obj: any = {}
    message.customerId !== undefined && (obj.customerId = message.customerId)
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.tenantName !== undefined && (obj.tenantName = message.tenantName)
    message.tenantSubdomain !== undefined && (obj.tenantSubdomain = message.tenantSubdomain)
    message.firstUser !== undefined && (obj.firstUser = message.firstUser ? User.toJSON(message.firstUser) : undefined)
    message.enableTendoEmployeeAccess !== undefined &&
      (obj.enableTendoEmployeeAccess = message.enableTendoEmployeeAccess)
    message.isProduction !== undefined && (obj.isProduction = message.isProduction)
    message.includeObjectData !== undefined && (obj.includeObjectData = message.includeObjectData)
    if (message.overrideObjectDataIds) {
      obj.overrideObjectDataIds = message.overrideObjectDataIds.map((e) => e)
    } else {
      obj.overrideObjectDataIds = []
    }
    message.dbCluster !== undefined && (obj.dbCluster = message.dbCluster)
    message.provisionDemoUsers !== undefined && (obj.provisionDemoUsers = message.provisionDemoUsers)
    message.demoUserBaseEmail !== undefined && (obj.demoUserBaseEmail = message.demoUserBaseEmail)
    message.provisionDemoUserAuth0Accounts !== undefined &&
      (obj.provisionDemoUserAuth0Accounts = message.provisionDemoUserAuth0Accounts)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<CreateTenantFromTemplateRequest>, I>>(
    object: I
  ): CreateTenantFromTemplateRequest {
    const message = createBaseCreateTenantFromTemplateRequest()
    message.customerId = object.customerId ?? ''
    message.templateId = object.templateId ?? ''
    message.tenantName = object.tenantName ?? ''
    message.tenantSubdomain = object.tenantSubdomain ?? ''
    message.firstUser =
      object.firstUser !== undefined && object.firstUser !== null ? User.fromPartial(object.firstUser) : undefined
    message.enableTendoEmployeeAccess = object.enableTendoEmployeeAccess ?? false
    message.isProduction = object.isProduction ?? false
    message.includeObjectData = object.includeObjectData ?? false
    message.overrideObjectDataIds = object.overrideObjectDataIds?.map((e) => e) || []
    message.dbCluster = object.dbCluster ?? ''
    message.provisionDemoUsers = object.provisionDemoUsers ?? false
    message.demoUserBaseEmail = object.demoUserBaseEmail ?? ''
    message.provisionDemoUserAuth0Accounts = object.provisionDemoUserAuth0Accounts ?? false
    return message
  },
}

function createBaseCreateTenantFromTemplateResponse(): CreateTenantFromTemplateResponse {
  return { tenant: undefined }
}

export const CreateTenantFromTemplateResponse = {
  encode(message: CreateTenantFromTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTenantFromTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseCreateTenantFromTemplateResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenant = Tenant.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): CreateTenantFromTemplateResponse {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
    }
  },

  toJSON(message: CreateTenantFromTemplateResponse): unknown {
    const obj: any = {}
    message.tenant !== undefined && (obj.tenant = message.tenant ? Tenant.toJSON(message.tenant) : undefined)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<CreateTenantFromTemplateResponse>, I>>(
    object: I
  ): CreateTenantFromTemplateResponse {
    const message = createBaseCreateTenantFromTemplateResponse()
    message.tenant =
      object.tenant !== undefined && object.tenant !== null ? Tenant.fromPartial(object.tenant) : undefined
    return message
  },
}

function createBaseDestroyTenantRequest(): DestroyTenantRequest {
  return { tenantId: '' }
}

export const DestroyTenantRequest = {
  encode(message: DestroyTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyTenantRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDestroyTenantRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DestroyTenantRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
    }
  },

  toJSON(message: DestroyTenantRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DestroyTenantRequest>, I>>(object: I): DestroyTenantRequest {
    const message = createBaseDestroyTenantRequest()
    message.tenantId = object.tenantId ?? ''
    return message
  },
}

function createBaseDestroyTenantResponse(): DestroyTenantResponse {
  return { tenant: undefined }
}

export const DestroyTenantResponse = {
  encode(message: DestroyTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyTenantResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDestroyTenantResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenant = Tenant.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DestroyTenantResponse {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
    }
  },

  toJSON(message: DestroyTenantResponse): unknown {
    const obj: any = {}
    message.tenant !== undefined && (obj.tenant = message.tenant ? Tenant.toJSON(message.tenant) : undefined)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DestroyTenantResponse>, I>>(object: I): DestroyTenantResponse {
    const message = createBaseDestroyTenantResponse()
    message.tenant =
      object.tenant !== undefined && object.tenant !== null ? Tenant.fromPartial(object.tenant) : undefined
    return message
  },
}

function createBaseDeleteTenantRequest(): DeleteTenantRequest {
  return { tenantId: '', force: false }
}

export const DeleteTenantRequest = {
  encode(message: DeleteTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    if (message.force === true) {
      writer.uint32(16).bool(message.force)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDeleteTenantRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        case 2:
          message.force = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DeleteTenantRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
      force: isSet(object.force) ? Boolean(object.force) : false,
    }
  },

  toJSON(message: DeleteTenantRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    message.force !== undefined && (obj.force = message.force)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DeleteTenantRequest>, I>>(object: I): DeleteTenantRequest {
    const message = createBaseDeleteTenantRequest()
    message.tenantId = object.tenantId ?? ''
    message.force = object.force ?? false
    return message
  },
}

function createBaseDeleteTenantResponse(): DeleteTenantResponse {
  return { tenant: undefined }
}

export const DeleteTenantResponse = {
  encode(message: DeleteTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDeleteTenantResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenant = Tenant.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DeleteTenantResponse {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
    }
  },

  toJSON(message: DeleteTenantResponse): unknown {
    const obj: any = {}
    message.tenant !== undefined && (obj.tenant = message.tenant ? Tenant.toJSON(message.tenant) : undefined)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DeleteTenantResponse>, I>>(object: I): DeleteTenantResponse {
    const message = createBaseDeleteTenantResponse()
    message.tenant =
      object.tenant !== undefined && object.tenant !== null ? Tenant.fromPartial(object.tenant) : undefined
    return message
  },
}

function createBaseInitiateTenantExportRequest(): InitiateTenantExportRequest {
  return {
    tenantId: '',
    templateName: '',
    templateDescription: '',
    includeObjectData: false,
  }
}

export const InitiateTenantExportRequest = {
  encode(message: InitiateTenantExportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    if (message.templateName !== '') {
      writer.uint32(18).string(message.templateName)
    }
    if (message.templateDescription !== '') {
      writer.uint32(26).string(message.templateDescription)
    }
    if (message.includeObjectData === true) {
      writer.uint32(32).bool(message.includeObjectData)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitiateTenantExportRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseInitiateTenantExportRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        case 2:
          message.templateName = reader.string()
          break
        case 3:
          message.templateDescription = reader.string()
          break
        case 4:
          message.includeObjectData = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): InitiateTenantExportRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
      templateName: isSet(object.templateName) ? String(object.templateName) : '',
      templateDescription: isSet(object.templateDescription) ? String(object.templateDescription) : '',
      includeObjectData: isSet(object.includeObjectData) ? Boolean(object.includeObjectData) : false,
    }
  },

  toJSON(message: InitiateTenantExportRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    message.templateName !== undefined && (obj.templateName = message.templateName)
    message.templateDescription !== undefined && (obj.templateDescription = message.templateDescription)
    message.includeObjectData !== undefined && (obj.includeObjectData = message.includeObjectData)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<InitiateTenantExportRequest>, I>>(object: I): InitiateTenantExportRequest {
    const message = createBaseInitiateTenantExportRequest()
    message.tenantId = object.tenantId ?? ''
    message.templateName = object.templateName ?? ''
    message.templateDescription = object.templateDescription ?? ''
    message.includeObjectData = object.includeObjectData ?? false
    return message
  },
}

function createBaseInitiateTenantExportResponse(): InitiateTenantExportResponse {
  return { templateId: '' }
}

export const InitiateTenantExportResponse = {
  encode(message: InitiateTenantExportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitiateTenantExportResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseInitiateTenantExportResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): InitiateTenantExportResponse {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
    }
  },

  toJSON(message: InitiateTenantExportResponse): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<InitiateTenantExportResponse>, I>>(object: I): InitiateTenantExportResponse {
    const message = createBaseInitiateTenantExportResponse()
    message.templateId = object.templateId ?? ''
    return message
  },
}

function createBaseGetTenantDestructionsRequest(): GetTenantDestructionsRequest {
  return { tenantId: '' }
}

export const GetTenantDestructionsRequest = {
  encode(message: GetTenantDestructionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantDestructionsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantDestructionsRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTenantDestructionsRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
    }
  },

  toJSON(message: GetTenantDestructionsRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantDestructionsRequest>, I>>(object: I): GetTenantDestructionsRequest {
    const message = createBaseGetTenantDestructionsRequest()
    message.tenantId = object.tenantId ?? ''
    return message
  },
}

function createBaseGetTenantDestructionsResponse(): GetTenantDestructionsResponse {
  return { tenantDestructions: [] }
}

export const GetTenantDestructionsResponse = {
  encode(message: GetTenantDestructionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenantDestructions) {
      TenantDestruction.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantDestructionsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTenantDestructionsResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantDestructions.push(TenantDestruction.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTenantDestructionsResponse {
    return {
      tenantDestructions: Array.isArray(object?.tenantDestructions)
        ? object.tenantDestructions.map((e: any) => TenantDestruction.fromJSON(e))
        : [],
    }
  },

  toJSON(message: GetTenantDestructionsResponse): unknown {
    const obj: any = {}
    if (message.tenantDestructions) {
      obj.tenantDestructions = message.tenantDestructions.map((e) => (e ? TenantDestruction.toJSON(e) : undefined))
    } else {
      obj.tenantDestructions = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTenantDestructionsResponse>, I>>(
    object: I
  ): GetTenantDestructionsResponse {
    const message = createBaseGetTenantDestructionsResponse()
    message.tenantDestructions = object.tenantDestructions?.map((e) => TenantDestruction.fromPartial(e)) || []
    return message
  },
}

function createBaseTenantDestruction(): TenantDestruction {
  return { service: '', status: '', errorMessage: '', name: '' }
}

export const TenantDestruction = {
  encode(message: TenantDestruction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.service !== '') {
      writer.uint32(10).string(message.service)
    }
    if (message.status !== '') {
      writer.uint32(18).string(message.status)
    }
    if (message.errorMessage !== '') {
      writer.uint32(26).string(message.errorMessage)
    }
    if (message.name !== '') {
      writer.uint32(34).string(message.name)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantDestruction {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTenantDestruction()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.service = reader.string()
          break
        case 2:
          message.status = reader.string()
          break
        case 3:
          message.errorMessage = reader.string()
          break
        case 4:
          message.name = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): TenantDestruction {
    return {
      service: isSet(object.service) ? String(object.service) : '',
      status: isSet(object.status) ? String(object.status) : '',
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : '',
      name: isSet(object.name) ? String(object.name) : '',
    }
  },

  toJSON(message: TenantDestruction): unknown {
    const obj: any = {}
    message.service !== undefined && (obj.service = message.service)
    message.status !== undefined && (obj.status = message.status)
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage)
    message.name !== undefined && (obj.name = message.name)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<TenantDestruction>, I>>(object: I): TenantDestruction {
    const message = createBaseTenantDestruction()
    message.service = object.service ?? ''
    message.status = object.status ?? ''
    message.errorMessage = object.errorMessage ?? ''
    message.name = object.name ?? ''
    return message
  },
}

function createBaseGetTemplateFragmentDeploymentsRequest(): GetTemplateFragmentDeploymentsRequest {
  return { tenantId: '' }
}

export const GetTemplateFragmentDeploymentsRequest = {
  encode(message: GetTemplateFragmentDeploymentsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateFragmentDeploymentsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTemplateFragmentDeploymentsRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTemplateFragmentDeploymentsRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
    }
  },

  toJSON(message: GetTemplateFragmentDeploymentsRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTemplateFragmentDeploymentsRequest>, I>>(
    object: I
  ): GetTemplateFragmentDeploymentsRequest {
    const message = createBaseGetTemplateFragmentDeploymentsRequest()
    message.tenantId = object.tenantId ?? ''
    return message
  },
}

function createBaseGetTemplateFragmentDeploymentsResponse(): GetTemplateFragmentDeploymentsResponse {
  return { templateFragmentDeployments: [] }
}

export const GetTemplateFragmentDeploymentsResponse = {
  encode(message: GetTemplateFragmentDeploymentsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templateFragmentDeployments) {
      TemplateFragmentDeployment.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateFragmentDeploymentsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTemplateFragmentDeploymentsResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateFragmentDeployments.push(TemplateFragmentDeployment.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTemplateFragmentDeploymentsResponse {
    return {
      templateFragmentDeployments: Array.isArray(object?.templateFragmentDeployments)
        ? object.templateFragmentDeployments.map((e: any) => TemplateFragmentDeployment.fromJSON(e))
        : [],
    }
  },

  toJSON(message: GetTemplateFragmentDeploymentsResponse): unknown {
    const obj: any = {}
    if (message.templateFragmentDeployments) {
      obj.templateFragmentDeployments = message.templateFragmentDeployments.map((e) =>
        e ? TemplateFragmentDeployment.toJSON(e) : undefined
      )
    } else {
      obj.templateFragmentDeployments = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTemplateFragmentDeploymentsResponse>, I>>(
    object: I
  ): GetTemplateFragmentDeploymentsResponse {
    const message = createBaseGetTemplateFragmentDeploymentsResponse()
    message.templateFragmentDeployments =
      object.templateFragmentDeployments?.map((e) => TemplateFragmentDeployment.fromPartial(e)) || []
    return message
  },
}

function createBaseTemplateFragmentDeployment(): TemplateFragmentDeployment {
  return { service: '', name: '', status: '', errorMessage: '' }
}

export const TemplateFragmentDeployment = {
  encode(message: TemplateFragmentDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.service !== '') {
      writer.uint32(10).string(message.service)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.status !== '') {
      writer.uint32(26).string(message.status)
    }
    if (message.errorMessage !== '') {
      writer.uint32(34).string(message.errorMessage)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TemplateFragmentDeployment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTemplateFragmentDeployment()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.service = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.status = reader.string()
          break
        case 4:
          message.errorMessage = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): TemplateFragmentDeployment {
    return {
      service: isSet(object.service) ? String(object.service) : '',
      name: isSet(object.name) ? String(object.name) : '',
      status: isSet(object.status) ? String(object.status) : '',
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : '',
    }
  },

  toJSON(message: TemplateFragmentDeployment): unknown {
    const obj: any = {}
    message.service !== undefined && (obj.service = message.service)
    message.name !== undefined && (obj.name = message.name)
    message.status !== undefined && (obj.status = message.status)
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<TemplateFragmentDeployment>, I>>(object: I): TemplateFragmentDeployment {
    const message = createBaseTemplateFragmentDeployment()
    message.service = object.service ?? ''
    message.name = object.name ?? ''
    message.status = object.status ?? ''
    message.errorMessage = object.errorMessage ?? ''
    return message
  },
}

function createBaseGetRenderedTemplateRequest(): GetRenderedTemplateRequest {
  return { templateId: '' }
}

export const GetRenderedTemplateRequest = {
  encode(message: GetRenderedTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetRenderedTemplateRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetRenderedTemplateRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
    }
  },

  toJSON(message: GetRenderedTemplateRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateRequest>, I>>(object: I): GetRenderedTemplateRequest {
    const message = createBaseGetRenderedTemplateRequest()
    message.templateId = object.templateId ?? ''
    return message
  },
}

function createBaseGetRenderedTemplateResponse(): GetRenderedTemplateResponse {
  return { templateData: new Uint8Array() }
}

export const GetRenderedTemplateResponse = {
  encode(message: GetRenderedTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateData.length !== 0) {
      writer.uint32(10).bytes(message.templateData)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetRenderedTemplateResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateData = reader.bytes()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetRenderedTemplateResponse {
    return {
      templateData: isSet(object.templateData) ? bytesFromBase64(object.templateData) : new Uint8Array(),
    }
  },

  toJSON(message: GetRenderedTemplateResponse): unknown {
    const obj: any = {}
    message.templateData !== undefined &&
      (obj.templateData = base64FromBytes(message.templateData !== undefined ? message.templateData : new Uint8Array()))
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateResponse>, I>>(object: I): GetRenderedTemplateResponse {
    const message = createBaseGetRenderedTemplateResponse()
    message.templateData = object.templateData ?? new Uint8Array()
    return message
  },
}

function createBaseGetRenderedTemplateFragmentRequest(): GetRenderedTemplateFragmentRequest {
  return { templateFragmentId: '' }
}

export const GetRenderedTemplateFragmentRequest = {
  encode(message: GetRenderedTemplateFragmentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateFragmentId !== '') {
      writer.uint32(10).string(message.templateFragmentId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateFragmentRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetRenderedTemplateFragmentRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateFragmentId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetRenderedTemplateFragmentRequest {
    return {
      templateFragmentId: isSet(object.templateFragmentId) ? String(object.templateFragmentId) : '',
    }
  },

  toJSON(message: GetRenderedTemplateFragmentRequest): unknown {
    const obj: any = {}
    message.templateFragmentId !== undefined && (obj.templateFragmentId = message.templateFragmentId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateFragmentRequest>, I>>(
    object: I
  ): GetRenderedTemplateFragmentRequest {
    const message = createBaseGetRenderedTemplateFragmentRequest()
    message.templateFragmentId = object.templateFragmentId ?? ''
    return message
  },
}

function createBaseGetRenderedTemplateFragmentResponse(): GetRenderedTemplateFragmentResponse {
  return { templateFragmentData: new Uint8Array() }
}

export const GetRenderedTemplateFragmentResponse = {
  encode(message: GetRenderedTemplateFragmentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateFragmentData.length !== 0) {
      writer.uint32(10).bytes(message.templateFragmentData)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateFragmentResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetRenderedTemplateFragmentResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateFragmentData = reader.bytes()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetRenderedTemplateFragmentResponse {
    return {
      templateFragmentData: isSet(object.templateFragmentData)
        ? bytesFromBase64(object.templateFragmentData)
        : new Uint8Array(),
    }
  },

  toJSON(message: GetRenderedTemplateFragmentResponse): unknown {
    const obj: any = {}
    message.templateFragmentData !== undefined &&
      (obj.templateFragmentData = base64FromBytes(
        message.templateFragmentData !== undefined ? message.templateFragmentData : new Uint8Array()
      ))
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateFragmentResponse>, I>>(
    object: I
  ): GetRenderedTemplateFragmentResponse {
    const message = createBaseGetRenderedTemplateFragmentResponse()
    message.templateFragmentData = object.templateFragmentData ?? new Uint8Array()
    return message
  },
}

function createBaseUploadTemplateRequest(): UploadTemplateRequest {
  return { templateData: new Uint8Array() }
}

export const UploadTemplateRequest = {
  encode(message: UploadTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateData.length !== 0) {
      writer.uint32(10).bytes(message.templateData)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUploadTemplateRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateData = reader.bytes()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UploadTemplateRequest {
    return {
      templateData: isSet(object.templateData) ? bytesFromBase64(object.templateData) : new Uint8Array(),
    }
  },

  toJSON(message: UploadTemplateRequest): unknown {
    const obj: any = {}
    message.templateData !== undefined &&
      (obj.templateData = base64FromBytes(message.templateData !== undefined ? message.templateData : new Uint8Array()))
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UploadTemplateRequest>, I>>(object: I): UploadTemplateRequest {
    const message = createBaseUploadTemplateRequest()
    message.templateData = object.templateData ?? new Uint8Array()
    return message
  },
}

function createBaseUploadTemplateResponse(): UploadTemplateResponse {
  return { templateId: '' }
}

export const UploadTemplateResponse = {
  encode(message: UploadTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUploadTemplateResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UploadTemplateResponse {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
    }
  },

  toJSON(message: UploadTemplateResponse): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UploadTemplateResponse>, I>>(object: I): UploadTemplateResponse {
    const message = createBaseUploadTemplateResponse()
    message.templateId = object.templateId ?? ''
    return message
  },
}

function createBaseGetTemplatesRequest(): GetTemplatesRequest {
  return { deployable: false }
}

export const GetTemplatesRequest = {
  encode(message: GetTemplatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deployable === true) {
      writer.uint32(8).bool(message.deployable)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplatesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTemplatesRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.deployable = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTemplatesRequest {
    return {
      deployable: isSet(object.deployable) ? Boolean(object.deployable) : false,
    }
  },

  toJSON(message: GetTemplatesRequest): unknown {
    const obj: any = {}
    message.deployable !== undefined && (obj.deployable = message.deployable)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTemplatesRequest>, I>>(object: I): GetTemplatesRequest {
    const message = createBaseGetTemplatesRequest()
    message.deployable = object.deployable ?? false
    return message
  },
}

function createBaseGetTemplatesResponse(): GetTemplatesResponse {
  return { templates: [] }
}

export const GetTemplatesResponse = {
  encode(message: GetTemplatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      Template.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplatesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTemplatesResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templates.push(Template.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTemplatesResponse {
    return {
      templates: Array.isArray(object?.templates) ? object.templates.map((e: any) => Template.fromJSON(e)) : [],
    }
  },

  toJSON(message: GetTemplatesResponse): unknown {
    const obj: any = {}
    if (message.templates) {
      obj.templates = message.templates.map((e) => (e ? Template.toJSON(e) : undefined))
    } else {
      obj.templates = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTemplatesResponse>, I>>(object: I): GetTemplatesResponse {
    const message = createBaseGetTemplatesResponse()
    message.templates = object.templates?.map((e) => Template.fromPartial(e)) || []
    return message
  },
}

function createBaseGetTemplateRequest(): GetTemplateRequest {
  return { templateId: '' }
}

export const GetTemplateRequest = {
  encode(message: GetTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTemplateRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTemplateRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
    }
  },

  toJSON(message: GetTemplateRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTemplateRequest>, I>>(object: I): GetTemplateRequest {
    const message = createBaseGetTemplateRequest()
    message.templateId = object.templateId ?? ''
    return message
  },
}

function createBaseGetTemplateResponse(): GetTemplateResponse {
  return { template: undefined }
}

export const GetTemplateResponse = {
  encode(message: GetTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.template !== undefined) {
      Template.encode(message.template, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetTemplateResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.template = Template.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetTemplateResponse {
    return {
      template: isSet(object.template) ? Template.fromJSON(object.template) : undefined,
    }
  },

  toJSON(message: GetTemplateResponse): unknown {
    const obj: any = {}
    message.template !== undefined && (obj.template = message.template ? Template.toJSON(message.template) : undefined)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetTemplateResponse>, I>>(object: I): GetTemplateResponse {
    const message = createBaseGetTemplateResponse()
    message.template =
      object.template !== undefined && object.template !== null ? Template.fromPartial(object.template) : undefined
    return message
  },
}

function createBaseTemplate(): Template {
  return {
    id: '',
    name: '',
    description: '',
    templateFragments: [],
    golden: false,
    archived: false,
    published: false,
    createdAt: undefined,
    createdFromTenantId: '',
    includesObjectData: false,
    objectData: [],
    emrless: false,
  }
}

export const Template = {
  encode(message: Template, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description)
    }
    for (const v of message.templateFragments) {
      TemplateFragment.encode(v!, writer.uint32(34).fork()).ldelim()
    }
    if (message.golden === true) {
      writer.uint32(40).bool(message.golden)
    }
    if (message.archived === true) {
      writer.uint32(48).bool(message.archived)
    }
    if (message.published === true) {
      writer.uint32(56).bool(message.published)
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(66).fork()).ldelim()
    }
    if (message.createdFromTenantId !== '') {
      writer.uint32(74).string(message.createdFromTenantId)
    }
    if (message.includesObjectData === true) {
      writer.uint32(80).bool(message.includesObjectData)
    }
    for (const v of message.objectData) {
      ObjectData.encode(v!, writer.uint32(90).fork()).ldelim()
    }
    if (message.emrless === true) {
      writer.uint32(96).bool(message.emrless)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Template {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTemplate()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.description = reader.string()
          break
        case 4:
          message.templateFragments.push(TemplateFragment.decode(reader, reader.uint32()))
          break
        case 5:
          message.golden = reader.bool()
          break
        case 6:
          message.archived = reader.bool()
          break
        case 7:
          message.published = reader.bool()
          break
        case 8:
          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        case 9:
          message.createdFromTenantId = reader.string()
          break
        case 10:
          message.includesObjectData = reader.bool()
          break
        case 11:
          message.objectData.push(ObjectData.decode(reader, reader.uint32()))
          break
        case 12:
          message.emrless = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Template {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      templateFragments: Array.isArray(object?.templateFragments)
        ? object.templateFragments.map((e: any) => TemplateFragment.fromJSON(e))
        : [],
      golden: isSet(object.golden) ? Boolean(object.golden) : false,
      archived: isSet(object.archived) ? Boolean(object.archived) : false,
      published: isSet(object.published) ? Boolean(object.published) : false,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      createdFromTenantId: isSet(object.createdFromTenantId) ? String(object.createdFromTenantId) : '',
      includesObjectData: isSet(object.includesObjectData) ? Boolean(object.includesObjectData) : false,
      objectData: Array.isArray(object?.objectData) ? object.objectData.map((e: any) => ObjectData.fromJSON(e)) : [],
      emrless: isSet(object.emrless) ? Boolean(object.emrless) : false,
    }
  },

  toJSON(message: Template): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.name !== undefined && (obj.name = message.name)
    message.description !== undefined && (obj.description = message.description)
    if (message.templateFragments) {
      obj.templateFragments = message.templateFragments.map((e) => (e ? TemplateFragment.toJSON(e) : undefined))
    } else {
      obj.templateFragments = []
    }
    message.golden !== undefined && (obj.golden = message.golden)
    message.archived !== undefined && (obj.archived = message.archived)
    message.published !== undefined && (obj.published = message.published)
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString())
    message.createdFromTenantId !== undefined && (obj.createdFromTenantId = message.createdFromTenantId)
    message.includesObjectData !== undefined && (obj.includesObjectData = message.includesObjectData)
    if (message.objectData) {
      obj.objectData = message.objectData.map((e) => (e ? ObjectData.toJSON(e) : undefined))
    } else {
      obj.objectData = []
    }
    message.emrless !== undefined && (obj.emrless = message.emrless)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<Template>, I>>(object: I): Template {
    const message = createBaseTemplate()
    message.id = object.id ?? ''
    message.name = object.name ?? ''
    message.description = object.description ?? ''
    message.templateFragments = object.templateFragments?.map((e) => TemplateFragment.fromPartial(e)) || []
    message.golden = object.golden ?? false
    message.archived = object.archived ?? false
    message.published = object.published ?? false
    message.createdAt = object.createdAt ?? undefined
    message.createdFromTenantId = object.createdFromTenantId ?? ''
    message.includesObjectData = object.includesObjectData ?? false
    message.objectData = object.objectData?.map((e) => ObjectData.fromPartial(e)) || []
    message.emrless = object.emrless ?? false
    return message
  },
}

function createBaseTemplateFragment(): TemplateFragment {
  return {
    id: '',
    name: '',
    service: '',
    status: '',
    errorMessage: '',
    createdAt: undefined,
    updatedAt: undefined,
  }
}

export const TemplateFragment = {
  encode(message: TemplateFragment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.service !== '') {
      writer.uint32(26).string(message.service)
    }
    if (message.status !== '') {
      writer.uint32(34).string(message.status)
    }
    if (message.errorMessage !== '') {
      writer.uint32(42).string(message.errorMessage)
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim()
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(58).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TemplateFragment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTemplateFragment()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.service = reader.string()
          break
        case 4:
          message.status = reader.string()
          break
        case 5:
          message.errorMessage = reader.string()
          break
        case 6:
          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        case 7:
          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): TemplateFragment {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      service: isSet(object.service) ? String(object.service) : '',
      status: isSet(object.status) ? String(object.status) : '',
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : '',
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
    }
  },

  toJSON(message: TemplateFragment): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.name !== undefined && (obj.name = message.name)
    message.service !== undefined && (obj.service = message.service)
    message.status !== undefined && (obj.status = message.status)
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage)
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString())
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt.toISOString())
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<TemplateFragment>, I>>(object: I): TemplateFragment {
    const message = createBaseTemplateFragment()
    message.id = object.id ?? ''
    message.name = object.name ?? ''
    message.service = object.service ?? ''
    message.status = object.status ?? ''
    message.errorMessage = object.errorMessage ?? ''
    message.createdAt = object.createdAt ?? undefined
    message.updatedAt = object.updatedAt ?? undefined
    return message
  },
}

function createBaseUpdateTemplateGoldenStatusRequest(): UpdateTemplateGoldenStatusRequest {
  return { templateId: '', golden: false }
}

export const UpdateTemplateGoldenStatusRequest = {
  encode(message: UpdateTemplateGoldenStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.golden === true) {
      writer.uint32(16).bool(message.golden)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateGoldenStatusRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplateGoldenStatusRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.golden = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplateGoldenStatusRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      golden: isSet(object.golden) ? Boolean(object.golden) : false,
    }
  },

  toJSON(message: UpdateTemplateGoldenStatusRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.golden !== undefined && (obj.golden = message.golden)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplateGoldenStatusRequest>, I>>(
    object: I
  ): UpdateTemplateGoldenStatusRequest {
    const message = createBaseUpdateTemplateGoldenStatusRequest()
    message.templateId = object.templateId ?? ''
    message.golden = object.golden ?? false
    return message
  },
}

function createBaseUpdateTemplateGoldenStatusResponse(): UpdateTemplateGoldenStatusResponse {
  return { templateId: '', golden: false }
}

export const UpdateTemplateGoldenStatusResponse = {
  encode(message: UpdateTemplateGoldenStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.golden === true) {
      writer.uint32(16).bool(message.golden)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateGoldenStatusResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplateGoldenStatusResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.golden = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplateGoldenStatusResponse {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      golden: isSet(object.golden) ? Boolean(object.golden) : false,
    }
  },

  toJSON(message: UpdateTemplateGoldenStatusResponse): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.golden !== undefined && (obj.golden = message.golden)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplateGoldenStatusResponse>, I>>(
    object: I
  ): UpdateTemplateGoldenStatusResponse {
    const message = createBaseUpdateTemplateGoldenStatusResponse()
    message.templateId = object.templateId ?? ''
    message.golden = object.golden ?? false
    return message
  },
}

function createBaseUpdateTemplatePublishedStatusRequest(): UpdateTemplatePublishedStatusRequest {
  return { templateId: '', published: false }
}

export const UpdateTemplatePublishedStatusRequest = {
  encode(message: UpdateTemplatePublishedStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.published === true) {
      writer.uint32(16).bool(message.published)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplatePublishedStatusRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplatePublishedStatusRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.published = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplatePublishedStatusRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      published: isSet(object.published) ? Boolean(object.published) : false,
    }
  },

  toJSON(message: UpdateTemplatePublishedStatusRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.published !== undefined && (obj.published = message.published)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplatePublishedStatusRequest>, I>>(
    object: I
  ): UpdateTemplatePublishedStatusRequest {
    const message = createBaseUpdateTemplatePublishedStatusRequest()
    message.templateId = object.templateId ?? ''
    message.published = object.published ?? false
    return message
  },
}

function createBaseUpdateTemplatePublishedStatusResponse(): UpdateTemplatePublishedStatusResponse {
  return { templateId: '', published: false }
}

export const UpdateTemplatePublishedStatusResponse = {
  encode(message: UpdateTemplatePublishedStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.published === true) {
      writer.uint32(16).bool(message.published)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplatePublishedStatusResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplatePublishedStatusResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.published = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplatePublishedStatusResponse {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      published: isSet(object.published) ? Boolean(object.published) : false,
    }
  },

  toJSON(message: UpdateTemplatePublishedStatusResponse): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.published !== undefined && (obj.published = message.published)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplatePublishedStatusResponse>, I>>(
    object: I
  ): UpdateTemplatePublishedStatusResponse {
    const message = createBaseUpdateTemplatePublishedStatusResponse()
    message.templateId = object.templateId ?? ''
    message.published = object.published ?? false
    return message
  },
}

function createBaseUpdateTemplateEmrlessStatusRequest(): UpdateTemplateEmrlessStatusRequest {
  return { templateId: '', emrless: false }
}

export const UpdateTemplateEmrlessStatusRequest = {
  encode(message: UpdateTemplateEmrlessStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.emrless === true) {
      writer.uint32(16).bool(message.emrless)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateEmrlessStatusRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplateEmrlessStatusRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.emrless = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplateEmrlessStatusRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      emrless: isSet(object.emrless) ? Boolean(object.emrless) : false,
    }
  },

  toJSON(message: UpdateTemplateEmrlessStatusRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.emrless !== undefined && (obj.emrless = message.emrless)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplateEmrlessStatusRequest>, I>>(
    object: I
  ): UpdateTemplateEmrlessStatusRequest {
    const message = createBaseUpdateTemplateEmrlessStatusRequest()
    message.templateId = object.templateId ?? ''
    message.emrless = object.emrless ?? false
    return message
  },
}

function createBaseUpdateTemplateEmrlessStatusResponse(): UpdateTemplateEmrlessStatusResponse {
  return { templateId: '', emrless: false }
}

export const UpdateTemplateEmrlessStatusResponse = {
  encode(message: UpdateTemplateEmrlessStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.emrless === true) {
      writer.uint32(16).bool(message.emrless)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateEmrlessStatusResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplateEmrlessStatusResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.emrless = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplateEmrlessStatusResponse {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      emrless: isSet(object.emrless) ? Boolean(object.emrless) : false,
    }
  },

  toJSON(message: UpdateTemplateEmrlessStatusResponse): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.emrless !== undefined && (obj.emrless = message.emrless)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplateEmrlessStatusResponse>, I>>(
    object: I
  ): UpdateTemplateEmrlessStatusResponse {
    const message = createBaseUpdateTemplateEmrlessStatusResponse()
    message.templateId = object.templateId ?? ''
    message.emrless = object.emrless ?? false
    return message
  },
}

function createBaseUpdateTemplateRequest(): UpdateTemplateRequest {
  return { templateId: '', name: '', description: '' }
}

export const UpdateTemplateRequest = {
  encode(message: UpdateTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplateRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.description = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplateRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
    }
  },

  toJSON(message: UpdateTemplateRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.name !== undefined && (obj.name = message.name)
    message.description !== undefined && (obj.description = message.description)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplateRequest>, I>>(object: I): UpdateTemplateRequest {
    const message = createBaseUpdateTemplateRequest()
    message.templateId = object.templateId ?? ''
    message.name = object.name ?? ''
    message.description = object.description ?? ''
    return message
  },
}

function createBaseUpdateTemplateResponse(): UpdateTemplateResponse {
  return { templateId: '', name: '', description: '' }
}

export const UpdateTemplateResponse = {
  encode(message: UpdateTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUpdateTemplateResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.description = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UpdateTemplateResponse {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
    }
  },

  toJSON(message: UpdateTemplateResponse): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.name !== undefined && (obj.name = message.name)
    message.description !== undefined && (obj.description = message.description)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTemplateResponse>, I>>(object: I): UpdateTemplateResponse {
    const message = createBaseUpdateTemplateResponse()
    message.templateId = object.templateId ?? ''
    message.name = object.name ?? ''
    message.description = object.description ?? ''
    return message
  },
}

function createBaseDeleteTemplateRequest(): DeleteTemplateRequest {
  return { templateId: '' }
}

export const DeleteTemplateRequest = {
  encode(message: DeleteTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDeleteTemplateRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DeleteTemplateRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
    }
  },

  toJSON(message: DeleteTemplateRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DeleteTemplateRequest>, I>>(object: I): DeleteTemplateRequest {
    const message = createBaseDeleteTemplateRequest()
    message.templateId = object.templateId ?? ''
    return message
  },
}

function createBaseDeleteTemplateResponse(): DeleteTemplateResponse {
  return {
    templateId: '',
    name: '',
    description: '',
    golden: false,
    archived: false,
  }
}

export const DeleteTemplateResponse = {
  encode(message: DeleteTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name)
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description)
    }
    if (message.golden === true) {
      writer.uint32(32).bool(message.golden)
    }
    if (message.archived === true) {
      writer.uint32(40).bool(message.archived)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDeleteTemplateResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.name = reader.string()
          break
        case 3:
          message.description = reader.string()
          break
        case 4:
          message.golden = reader.bool()
          break
        case 5:
          message.archived = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DeleteTemplateResponse {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      golden: isSet(object.golden) ? Boolean(object.golden) : false,
      archived: isSet(object.archived) ? Boolean(object.archived) : false,
    }
  },

  toJSON(message: DeleteTemplateResponse): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.name !== undefined && (obj.name = message.name)
    message.description !== undefined && (obj.description = message.description)
    message.golden !== undefined && (obj.golden = message.golden)
    message.archived !== undefined && (obj.archived = message.archived)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DeleteTemplateResponse>, I>>(object: I): DeleteTemplateResponse {
    const message = createBaseDeleteTemplateResponse()
    message.templateId = object.templateId ?? ''
    message.name = object.name ?? ''
    message.description = object.description ?? ''
    message.golden = object.golden ?? false
    message.archived = object.archived ?? false
    return message
  },
}

function createBaseObjectData(): ObjectData {
  return {
    id: '',
    model: '',
    numberRecords: 0,
    status: '',
    createdAt: undefined,
    updatedAt: undefined,
  }
}

export const ObjectData = {
  encode(message: ObjectData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.model !== '') {
      writer.uint32(18).string(message.model)
    }
    if (message.numberRecords !== 0) {
      writer.uint32(24).int64(message.numberRecords)
    }
    if (message.status !== '') {
      writer.uint32(34).string(message.status)
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim()
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseObjectData()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.model = reader.string()
          break
        case 3:
          message.numberRecords = longToNumber(reader.int64() as Long)
          break
        case 4:
          message.status = reader.string()
          break
        case 5:
          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        case 6:
          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ObjectData {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      model: isSet(object.model) ? String(object.model) : '',
      numberRecords: isSet(object.numberRecords) ? Number(object.numberRecords) : 0,
      status: isSet(object.status) ? String(object.status) : '',
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
    }
  },

  toJSON(message: ObjectData): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.model !== undefined && (obj.model = message.model)
    message.numberRecords !== undefined && (obj.numberRecords = Math.round(message.numberRecords))
    message.status !== undefined && (obj.status = message.status)
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString())
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt.toISOString())
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<ObjectData>, I>>(object: I): ObjectData {
    const message = createBaseObjectData()
    message.id = object.id ?? ''
    message.model = object.model ?? ''
    message.numberRecords = object.numberRecords ?? 0
    message.status = object.status ?? ''
    message.createdAt = object.createdAt ?? undefined
    message.updatedAt = object.updatedAt ?? undefined
    return message
  },
}

function createBaseUploadObjectDataRequest(): UploadObjectDataRequest {
  return { templateId: '', data: new Uint8Array() }
}

export const UploadObjectDataRequest = {
  encode(message: UploadObjectDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    if (message.data.length !== 0) {
      writer.uint32(18).bytes(message.data)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadObjectDataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUploadObjectDataRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        case 2:
          message.data = reader.bytes()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UploadObjectDataRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
      data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
    }
  },

  toJSON(message: UploadObjectDataRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    message.data !== undefined &&
      (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()))
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UploadObjectDataRequest>, I>>(object: I): UploadObjectDataRequest {
    const message = createBaseUploadObjectDataRequest()
    message.templateId = object.templateId ?? ''
    message.data = object.data ?? new Uint8Array()
    return message
  },
}

function createBaseUploadObjectDataResponse(): UploadObjectDataResponse {
  return { objectData: [] }
}

export const UploadObjectDataResponse = {
  encode(message: UploadObjectDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectData) {
      ObjectData.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadObjectDataResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUploadObjectDataResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.objectData.push(ObjectData.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UploadObjectDataResponse {
    return {
      objectData: Array.isArray(object?.objectData) ? object.objectData.map((e: any) => ObjectData.fromJSON(e)) : [],
    }
  },

  toJSON(message: UploadObjectDataResponse): unknown {
    const obj: any = {}
    if (message.objectData) {
      obj.objectData = message.objectData.map((e) => (e ? ObjectData.toJSON(e) : undefined))
    } else {
      obj.objectData = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UploadObjectDataResponse>, I>>(object: I): UploadObjectDataResponse {
    const message = createBaseUploadObjectDataResponse()
    message.objectData = object.objectData?.map((e) => ObjectData.fromPartial(e)) || []
    return message
  },
}

function createBaseListObjectDBClustersRequest(): ListObjectDBClustersRequest {
  return {}
}

export const ListObjectDBClustersRequest = {
  encode(_: ListObjectDBClustersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListObjectDBClustersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseListObjectDBClustersRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(_: any): ListObjectDBClustersRequest {
    return {}
  },

  toJSON(_: ListObjectDBClustersRequest): unknown {
    const obj: any = {}
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<ListObjectDBClustersRequest>, I>>(_: I): ListObjectDBClustersRequest {
    const message = createBaseListObjectDBClustersRequest()
    return message
  },
}

function createBaseListObjectDBClustersResponse(): ListObjectDBClustersResponse {
  return { dbClusters: [] }
}

export const ListObjectDBClustersResponse = {
  encode(message: ListObjectDBClustersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dbClusters) {
      writer.uint32(10).string(v!)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListObjectDBClustersResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseListObjectDBClustersResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.dbClusters.push(reader.string())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ListObjectDBClustersResponse {
    return {
      dbClusters: Array.isArray(object?.dbClusters) ? object.dbClusters.map((e: any) => String(e)) : [],
    }
  },

  toJSON(message: ListObjectDBClustersResponse): unknown {
    const obj: any = {}
    if (message.dbClusters) {
      obj.dbClusters = message.dbClusters.map((e) => e)
    } else {
      obj.dbClusters = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<ListObjectDBClustersResponse>, I>>(object: I): ListObjectDBClustersResponse {
    const message = createBaseListObjectDBClustersResponse()
    message.dbClusters = object.dbClusters?.map((e) => e) || []
    return message
  },
}

function createBaseGetObjectDataRequest(): GetObjectDataRequest {
  return { templateId: '' }
}

export const GetObjectDataRequest = {
  encode(message: GetObjectDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetObjectDataRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetObjectDataRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
    }
  },

  toJSON(message: GetObjectDataRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetObjectDataRequest>, I>>(object: I): GetObjectDataRequest {
    const message = createBaseGetObjectDataRequest()
    message.templateId = object.templateId ?? ''
    return message
  },
}

function createBaseGetObjectDataResponse(): GetObjectDataResponse {
  return { objectData: [] }
}

export const GetObjectDataResponse = {
  encode(message: GetObjectDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectData) {
      ObjectData.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetObjectDataResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.objectData.push(ObjectData.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetObjectDataResponse {
    return {
      objectData: Array.isArray(object?.objectData) ? object.objectData.map((e: any) => ObjectData.fromJSON(e)) : [],
    }
  },

  toJSON(message: GetObjectDataResponse): unknown {
    const obj: any = {}
    if (message.objectData) {
      obj.objectData = message.objectData.map((e) => (e ? ObjectData.toJSON(e) : undefined))
    } else {
      obj.objectData = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetObjectDataResponse>, I>>(object: I): GetObjectDataResponse {
    const message = createBaseGetObjectDataResponse()
    message.objectData = object.objectData?.map((e) => ObjectData.fromPartial(e)) || []
    return message
  },
}

function createBaseGetObjectDataPackageRequest(): GetObjectDataPackageRequest {
  return { templateId: '' }
}

export const GetObjectDataPackageRequest = {
  encode(message: GetObjectDataPackageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== '') {
      writer.uint32(10).string(message.templateId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataPackageRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetObjectDataPackageRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.templateId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetObjectDataPackageRequest {
    return {
      templateId: isSet(object.templateId) ? String(object.templateId) : '',
    }
  },

  toJSON(message: GetObjectDataPackageRequest): unknown {
    const obj: any = {}
    message.templateId !== undefined && (obj.templateId = message.templateId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetObjectDataPackageRequest>, I>>(object: I): GetObjectDataPackageRequest {
    const message = createBaseGetObjectDataPackageRequest()
    message.templateId = object.templateId ?? ''
    return message
  },
}

function createBaseGetObjectDataPackageResponse(): GetObjectDataPackageResponse {
  return { downloadUrl: '' }
}

export const GetObjectDataPackageResponse = {
  encode(message: GetObjectDataPackageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.downloadUrl !== '') {
      writer.uint32(10).string(message.downloadUrl)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataPackageResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetObjectDataPackageResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.downloadUrl = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetObjectDataPackageResponse {
    return {
      downloadUrl: isSet(object.downloadUrl) ? String(object.downloadUrl) : '',
    }
  },

  toJSON(message: GetObjectDataPackageResponse): unknown {
    const obj: any = {}
    message.downloadUrl !== undefined && (obj.downloadUrl = message.downloadUrl)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetObjectDataPackageResponse>, I>>(object: I): GetObjectDataPackageResponse {
    const message = createBaseGetObjectDataPackageResponse()
    message.downloadUrl = object.downloadUrl ?? ''
    return message
  },
}

function createBaseObjectDataDeployment(): ObjectDataDeployment {
  return {
    id: '',
    model: '',
    numberRecords: 0,
    status: '',
    createdAt: undefined,
    updatedAt: undefined,
  }
}

export const ObjectDataDeployment = {
  encode(message: ObjectDataDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.model !== '') {
      writer.uint32(18).string(message.model)
    }
    if (message.numberRecords !== 0) {
      writer.uint32(24).int64(message.numberRecords)
    }
    if (message.status !== '') {
      writer.uint32(34).string(message.status)
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim()
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectDataDeployment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseObjectDataDeployment()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.model = reader.string()
          break
        case 3:
          message.numberRecords = longToNumber(reader.int64() as Long)
          break
        case 4:
          message.status = reader.string()
          break
        case 5:
          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        case 6:
          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ObjectDataDeployment {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      model: isSet(object.model) ? String(object.model) : '',
      numberRecords: isSet(object.numberRecords) ? Number(object.numberRecords) : 0,
      status: isSet(object.status) ? String(object.status) : '',
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
    }
  },

  toJSON(message: ObjectDataDeployment): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.model !== undefined && (obj.model = message.model)
    message.numberRecords !== undefined && (obj.numberRecords = Math.round(message.numberRecords))
    message.status !== undefined && (obj.status = message.status)
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString())
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt.toISOString())
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<ObjectDataDeployment>, I>>(object: I): ObjectDataDeployment {
    const message = createBaseObjectDataDeployment()
    message.id = object.id ?? ''
    message.model = object.model ?? ''
    message.numberRecords = object.numberRecords ?? 0
    message.status = object.status ?? ''
    message.createdAt = object.createdAt ?? undefined
    message.updatedAt = object.updatedAt ?? undefined
    return message
  },
}

function createBaseGetObjectDataDeploymentsRequest(): GetObjectDataDeploymentsRequest {
  return { tenantId: '' }
}

export const GetObjectDataDeploymentsRequest = {
  encode(message: GetObjectDataDeploymentsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataDeploymentsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetObjectDataDeploymentsRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetObjectDataDeploymentsRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
    }
  },

  toJSON(message: GetObjectDataDeploymentsRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetObjectDataDeploymentsRequest>, I>>(
    object: I
  ): GetObjectDataDeploymentsRequest {
    const message = createBaseGetObjectDataDeploymentsRequest()
    message.tenantId = object.tenantId ?? ''
    return message
  },
}

function createBaseGetObjectDataDeploymentsResponse(): GetObjectDataDeploymentsResponse {
  return { objectDataDeployments: [] }
}

export const GetObjectDataDeploymentsResponse = {
  encode(message: GetObjectDataDeploymentsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectDataDeployments) {
      ObjectDataDeployment.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataDeploymentsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetObjectDataDeploymentsResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.objectDataDeployments.push(ObjectDataDeployment.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetObjectDataDeploymentsResponse {
    return {
      objectDataDeployments: Array.isArray(object?.objectDataDeployments)
        ? object.objectDataDeployments.map((e: any) => ObjectDataDeployment.fromJSON(e))
        : [],
    }
  },

  toJSON(message: GetObjectDataDeploymentsResponse): unknown {
    const obj: any = {}
    if (message.objectDataDeployments) {
      obj.objectDataDeployments = message.objectDataDeployments.map((e) =>
        e ? ObjectDataDeployment.toJSON(e) : undefined
      )
    } else {
      obj.objectDataDeployments = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetObjectDataDeploymentsResponse>, I>>(
    object: I
  ): GetObjectDataDeploymentsResponse {
    const message = createBaseGetObjectDataDeploymentsResponse()
    message.objectDataDeployments = object.objectDataDeployments?.map((e) => ObjectDataDeployment.fromPartial(e)) || []
    return message
  },
}

function createBaseDeployObjectDataRequest(): DeployObjectDataRequest {
  return { tenantId: '', objectDataIds: [] }
}

export const DeployObjectDataRequest = {
  encode(message: DeployObjectDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    for (const v of message.objectDataIds) {
      writer.uint32(18).string(v!)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeployObjectDataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDeployObjectDataRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        case 2:
          message.objectDataIds.push(reader.string())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DeployObjectDataRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
      objectDataIds: Array.isArray(object?.objectDataIds) ? object.objectDataIds.map((e: any) => String(e)) : [],
    }
  },

  toJSON(message: DeployObjectDataRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    if (message.objectDataIds) {
      obj.objectDataIds = message.objectDataIds.map((e) => e)
    } else {
      obj.objectDataIds = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DeployObjectDataRequest>, I>>(object: I): DeployObjectDataRequest {
    const message = createBaseDeployObjectDataRequest()
    message.tenantId = object.tenantId ?? ''
    message.objectDataIds = object.objectDataIds?.map((e) => e) || []
    return message
  },
}

function createBaseDeployObjectDataResponse(): DeployObjectDataResponse {
  return { objectDataDeployments: [] }
}

export const DeployObjectDataResponse = {
  encode(message: DeployObjectDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectDataDeployments) {
      ObjectDataDeployment.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeployObjectDataResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseDeployObjectDataResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.objectDataDeployments.push(ObjectDataDeployment.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DeployObjectDataResponse {
    return {
      objectDataDeployments: Array.isArray(object?.objectDataDeployments)
        ? object.objectDataDeployments.map((e: any) => ObjectDataDeployment.fromJSON(e))
        : [],
    }
  },

  toJSON(message: DeployObjectDataResponse): unknown {
    const obj: any = {}
    if (message.objectDataDeployments) {
      obj.objectDataDeployments = message.objectDataDeployments.map((e) =>
        e ? ObjectDataDeployment.toJSON(e) : undefined
      )
    } else {
      obj.objectDataDeployments = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<DeployObjectDataResponse>, I>>(object: I): DeployObjectDataResponse {
    const message = createBaseDeployObjectDataResponse()
    message.objectDataDeployments = object.objectDataDeployments?.map((e) => ObjectDataDeployment.fromPartial(e)) || []
    return message
  },
}

function createBaseUserProvisioningDeployment(): UserProvisioningDeployment {
  return {
    id: '',
    status: '',
    errorMessage: '',
    executionId: '',
    demoUserEmail: '',
  }
}

export const UserProvisioningDeployment = {
  encode(message: UserProvisioningDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.status !== '') {
      writer.uint32(18).string(message.status)
    }
    if (message.errorMessage !== '') {
      writer.uint32(26).string(message.errorMessage)
    }
    if (message.executionId !== '') {
      writer.uint32(34).string(message.executionId)
    }
    if (message.demoUserEmail !== '') {
      writer.uint32(42).string(message.demoUserEmail)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProvisioningDeployment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseUserProvisioningDeployment()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string()
          break
        case 2:
          message.status = reader.string()
          break
        case 3:
          message.errorMessage = reader.string()
          break
        case 4:
          message.executionId = reader.string()
          break
        case 5:
          message.demoUserEmail = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): UserProvisioningDeployment {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      status: isSet(object.status) ? String(object.status) : '',
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : '',
      executionId: isSet(object.executionId) ? String(object.executionId) : '',
      demoUserEmail: isSet(object.demoUserEmail) ? String(object.demoUserEmail) : '',
    }
  },

  toJSON(message: UserProvisioningDeployment): unknown {
    const obj: any = {}
    message.id !== undefined && (obj.id = message.id)
    message.status !== undefined && (obj.status = message.status)
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage)
    message.executionId !== undefined && (obj.executionId = message.executionId)
    message.demoUserEmail !== undefined && (obj.demoUserEmail = message.demoUserEmail)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<UserProvisioningDeployment>, I>>(object: I): UserProvisioningDeployment {
    const message = createBaseUserProvisioningDeployment()
    message.id = object.id ?? ''
    message.status = object.status ?? ''
    message.errorMessage = object.errorMessage ?? ''
    message.executionId = object.executionId ?? ''
    message.demoUserEmail = object.demoUserEmail ?? ''
    return message
  },
}

function createBaseGetUserProvisioningDeploymentsRequest(): GetUserProvisioningDeploymentsRequest {
  return { tenantId: '' }
}

export const GetUserProvisioningDeploymentsRequest = {
  encode(message: GetUserProvisioningDeploymentsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== '') {
      writer.uint32(10).string(message.tenantId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserProvisioningDeploymentsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetUserProvisioningDeploymentsRequest()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.tenantId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetUserProvisioningDeploymentsRequest {
    return {
      tenantId: isSet(object.tenantId) ? String(object.tenantId) : '',
    }
  },

  toJSON(message: GetUserProvisioningDeploymentsRequest): unknown {
    const obj: any = {}
    message.tenantId !== undefined && (obj.tenantId = message.tenantId)
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetUserProvisioningDeploymentsRequest>, I>>(
    object: I
  ): GetUserProvisioningDeploymentsRequest {
    const message = createBaseGetUserProvisioningDeploymentsRequest()
    message.tenantId = object.tenantId ?? ''
    return message
  },
}

function createBaseGetUserProvisioningDeploymentsResponse(): GetUserProvisioningDeploymentsResponse {
  return { userProvisioningDeployments: [] }
}

export const GetUserProvisioningDeploymentsResponse = {
  encode(message: GetUserProvisioningDeploymentsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.userProvisioningDeployments) {
      UserProvisioningDeployment.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserProvisioningDeploymentsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseGetUserProvisioningDeploymentsResponse()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.userProvisioningDeployments.push(UserProvisioningDeployment.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GetUserProvisioningDeploymentsResponse {
    return {
      userProvisioningDeployments: Array.isArray(object?.userProvisioningDeployments)
        ? object.userProvisioningDeployments.map((e: any) => UserProvisioningDeployment.fromJSON(e))
        : [],
    }
  },

  toJSON(message: GetUserProvisioningDeploymentsResponse): unknown {
    const obj: any = {}
    if (message.userProvisioningDeployments) {
      obj.userProvisioningDeployments = message.userProvisioningDeployments.map((e) =>
        e ? UserProvisioningDeployment.toJSON(e) : undefined
      )
    } else {
      obj.userProvisioningDeployments = []
    }
    return obj
  },

  fromPartial<I extends Exact<DeepPartial<GetUserProvisioningDeploymentsResponse>, I>>(
    object: I
  ): GetUserProvisioningDeploymentsResponse {
    const message = createBaseGetUserProvisioningDeploymentsResponse()
    message.userProvisioningDeployments =
      object.userProvisioningDeployments?.map((e) => UserProvisioningDeployment.fromPartial(e)) || []
    return message
  },
}

export interface TendoCloudConfiguratorService {
  CreateCustomer(request: CreateCustomerRequest): Promise<CreateCustomerResponse>
  GetCustomers(request: GetCustomersRequest): Promise<GetCustomersResponse>
  GetCustomer(request: GetCustomerRequest): Promise<GetCustomerResponse>
  GetTenants(request: GetTenantsRequest): Promise<GetTenantsResponse>
  GetTenant(request: GetTenantRequest): Promise<GetTenantResponse>
  GetTenantTemplates(request: GetTenantTemplatesRequest): Promise<GetTenantTemplatesResponse>
  GetTemplates(request: GetTemplatesRequest): Promise<GetTemplatesResponse>
  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse>
  CreateTenantFromTemplate(request: CreateTenantFromTemplateRequest): Promise<CreateTenantFromTemplateResponse>
  GetRenderedTemplate(request: GetRenderedTemplateRequest): Promise<GetRenderedTemplateResponse>
  GetRenderedTemplateFragment(request: GetRenderedTemplateFragmentRequest): Promise<GetRenderedTemplateFragmentResponse>
  UploadTemplate(request: UploadTemplateRequest): Promise<UploadTemplateResponse>
  UpdateTemplate(request: UpdateTemplateRequest): Promise<UpdateTemplateResponse>
  UpdateTemplateGoldenStatus(request: UpdateTemplateGoldenStatusRequest): Promise<UpdateTemplateGoldenStatusResponse>
  UpdateTemplatePublishedStatus(
    request: UpdateTemplatePublishedStatusRequest
  ): Promise<UpdateTemplatePublishedStatusResponse>
  UpdateTemplateEmrlessStatus(request: UpdateTemplateEmrlessStatusRequest): Promise<UpdateTemplateEmrlessStatusResponse>
  DeleteTemplate(request: DeleteTemplateRequest): Promise<DeleteTemplateResponse>
  DestroyTenant(request: DestroyTenantRequest): Promise<DestroyTenantResponse>
  DeleteTenant(request: DeleteTenantRequest): Promise<DeleteTenantResponse>
  GetTenantDestructions(request: GetTenantDestructionsRequest): Promise<GetTenantDestructionsResponse>
  GetTemplateFragmentDeployments(
    request: GetTemplateFragmentDeploymentsRequest
  ): Promise<GetTemplateFragmentDeploymentsResponse>
  InitiateTenantExport(request: InitiateTenantExportRequest): Promise<InitiateTenantExportResponse>
  ListObjectDBClusters(request: ListObjectDBClustersRequest): Promise<ListObjectDBClustersResponse>
  UploadObjectData(request: UploadObjectDataRequest): Promise<UploadObjectDataResponse>
  DeployObjectData(request: DeployObjectDataRequest): Promise<DeployObjectDataResponse>
  GetObjectData(request: GetObjectDataRequest): Promise<GetObjectDataResponse>
  GetObjectDataPackage(request: GetObjectDataPackageRequest): Promise<GetObjectDataPackageResponse>
  GetObjectDataDeployments(request: GetObjectDataDeploymentsRequest): Promise<GetObjectDataDeploymentsResponse>
  GetUserProvisioningDeployments(
    request: GetUserProvisioningDeploymentsRequest
  ): Promise<GetUserProvisioningDeploymentsResponse>
}

declare var self: any | undefined
declare var window: any | undefined
declare var global: any | undefined
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis
  if (typeof self !== 'undefined') return self
  if (typeof window !== 'undefined') return window
  if (typeof global !== 'undefined') return global
  throw 'Unable to locate global object'
})()

const atob: (b64: string) => string =
  globalThis.atob || ((b64) => globalThis.Buffer.from(b64, 'base64').toString('binary'))
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64)
  const arr = new Uint8Array(bin.length)
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i)
  }
  return arr
}

const btoa: (bin: string) => string =
  globalThis.btoa || ((bin) => globalThis.Buffer.from(bin, 'binary').toString('base64'))
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = []
  for (const byte of arr) {
    bin.push(String.fromCharCode(byte))
  }
  return btoa(bin.join(''))
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<Exclude<keyof I, KeysOfUnion<P>>, never>

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000
  const nanos = (date.getTime() % 1_000) * 1_000_000
  return { seconds, nanos }
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000
  millis += t.nanos / 1_000_000
  return new Date(millis)
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o
  } else if (typeof o === 'string') {
    return new Date(o)
  } else {
    return fromTimestamp(Timestamp.fromJSON(o))
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER')
  }
  return long.toNumber()
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
