import { Button, Box, FormField, FileUpload, Modal, SpaceBetween } from '@cloudscape-design/components'
import { submitTemplate } from 'lib/template'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModalData } from './ModalContext'

export const TemplateUploadModalName = 'TemplateUpload'

export const TemplateUploadModal = () => {
  const [f, setF] = useState<File>()

  const navigate = useNavigate()

  const { visible, disableModal } = useModalData(TemplateUploadModalName)

  const handleFileChange = async (fs: File[]) => {
    if (fs.length > 1) {
      throw new Error('unexpected multifile upload')
    }
    const f = fs.length === 1 ? fs[0] : undefined
    setF(f)
  }

  const handleSubmitTemplate = async () => {
    const templateId = await submitTemplate(f!)
    navigate(`/templates/${templateId}`)
    disableModal()
  }

  return (
    <Modal
      onDismiss={disableModal}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmitTemplate} disabled={!f}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Upload a Template"
    >
      <Box>
        <FormField label="Select a valid JSON template file">
          <FileUpload
            accept=".json"
            onChange={({ detail }) => handleFileChange(detail.value)}
            value={f ? [f] : []}
            i18nStrings={{
              uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
              dropzoneText: (e) => (e ? 'Drop files to upload' : 'Drop file to upload'),
              removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
              limitShowFewer: 'Show fewer files',
              limitShowMore: 'Show more files',
              errorIconAriaLabel: 'Error',
            }}
            showFileLastModified
            showFileSize
          />
        </FormField>
      </Box>
    </Modal>
  )
}
