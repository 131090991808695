import { Modal } from '@cloudscape-design/components'
import { useModalData } from './ModalContext'
import { ObjectData } from 'twirp/api'
import { ObjectDataTable } from './ObjectDataTable'

export const ObjectDataTableModalName = 'UploadObjectDataModal'

export interface ObjectDataTableModalProps {
  objectData: ObjectData[]
}

export const ObjectDataTableModal = ({ objectData }: ObjectDataTableModalProps) => {
  const { disableModal, visible } = useModalData(ObjectDataTableModalName)
  return (
    <Modal onDismiss={disableModal} visible={visible} header="Object Data Overview">
      <ObjectDataTable objectData={objectData} variant="borderless" />
    </Modal>
  )
}
