import { Flashbar, FlashbarProps } from '@cloudscape-design/components'
import { createContext, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export const useFlashMessages = () => {
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([])

  const pushFlashMessage = (type: FlashMessageType, message: string) => {
    setFlashMessages([
      ...flashMessages,
      {
        id: uuidv4(),
        message: message,
        type: type,
      },
    ])
  }

  const removeFlashMessage = (id: string) => {
    const updatedMessages = flashMessages.filter((m): boolean => m.id !== id)
    setFlashMessages(updatedMessages)
  }

  return {
    flashMessages,
    pushFlashMessage,
    removeFlashMessage,
  }
}

export type FlashMessageType = FlashbarProps.Type
export interface FlashMessage {
  id: string
  message: string
  type: FlashMessageType
}

export interface IFlashbarContext {
  messages: FlashMessage[]
  pushMessage: (t: FlashMessageType, m: string) => void
  removeMessage: (id: string) => void
}

export const FlashbarContext = createContext<IFlashbarContext>({
  messages: [],
  // eslint-disable  @typescript-eslint/no-empty-function
  pushMessage: (_t: FlashMessageType, _m: string) => {},
  removeMessage: (_id: string) => {},
})

export const FlashMessages = () => {
  const { messages, removeMessage } = useContext(FlashbarContext)
  return (
    <Flashbar
      items={messages.map((m) => ({
        type: m.type,
        content: m.message,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => removeMessage(m.id!),
        id: m.id,
      }))}
    />
  )
}
