import { Modal } from '@cloudscape-design/components'
import { useModalData } from './ModalContext'
import { TemplateContent } from './TemplateContent'

export const TemplateContentModalName = 'TemplateContentModal'

export const TemplateContentModal = (props: { templateId: string }) => {
  const { visible, disableModal } = useModalData(TemplateContentModalName)

  return (
    <Modal size="max" header="Template JSON" onDismiss={disableModal} visible={visible} closeAriaLabel="Close modal">
      {/* HACK: the template content could be large and time-intensive to parse.
          JavaScript is a single-threaded mess, so performing this parsing can lock
          up the browser and make the page unusable.
          For this reason, don't actually mount the component onto the DOM until
          the modal is marked 'visible'
      */}
      {visible && <TemplateContent {...props} />}
    </Modal>
  )
}
