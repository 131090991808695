import { Box, Button, SpaceBetween, StatusIndicator, TextContent } from '@cloudscape-design/components'
import Popover from '@cloudscape-design/components/popover'

// CopyOnClickText renders text with a copy button next to it, which will copy the text to the clipboard
// when clicked. If `value` is undefined or an empty string, will not render the copy button (useful for
// providing empty values during "loading" situations)
export const CopyOnClickText = ({ label, value }: { label?: string; value?: string }) => (
  <Box margin={{ right: 'xxs' }} display="inline-block">
    {value && (
      <SpaceBetween direction="horizontal" size="xxs">
        <Popover
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={<StatusIndicator type="success">Copied to clipboard</StatusIndicator>}
        >
          <Button
            variant="inline-icon"
            iconName="copy"
            onClick={() => {
              navigator.clipboard.writeText(value)
            }}
          />
        </Popover>

        <TextContent>{label || value}</TextContent>
      </SpaceBetween>
    )}
  </Box>
)
