import {
  CreateCustomerRequest,
  CreateCustomerResponse,
  GetCustomersRequest,
  GetCustomersResponse,
  GetCustomerRequest,
  GetCustomerResponse,
  GetTenantsRequest,
  GetTenantsResponse,
  GetTenantRequest,
  GetTenantResponse,
  GetTenantTemplatesRequest,
  GetTenantTemplatesResponse,
  GetTemplatesRequest,
  GetTemplatesResponse,
  GetTemplateRequest,
  GetTemplateResponse,
  CreateTenantFromTemplateRequest,
  CreateTenantFromTemplateResponse,
  GetRenderedTemplateRequest,
  GetRenderedTemplateResponse,
  GetRenderedTemplateFragmentRequest,
  GetRenderedTemplateFragmentResponse,
  UploadTemplateRequest,
  UploadTemplateResponse,
  UpdateTemplateRequest,
  UpdateTemplateResponse,
  UpdateTemplateGoldenStatusRequest,
  UpdateTemplateGoldenStatusResponse,
  UpdateTemplatePublishedStatusRequest,
  UpdateTemplatePublishedStatusResponse,
  UpdateTemplateEmrlessStatusRequest,
  UpdateTemplateEmrlessStatusResponse,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
  DestroyTenantRequest,
  DestroyTenantResponse,
  DeleteTenantRequest,
  DeleteTenantResponse,
  GetTenantDestructionsRequest,
  GetTenantDestructionsResponse,
  GetTemplateFragmentDeploymentsRequest,
  GetTemplateFragmentDeploymentsResponse,
  InitiateTenantExportRequest,
  InitiateTenantExportResponse,
  ListObjectDBClustersRequest,
  ListObjectDBClustersResponse,
  UploadObjectDataRequest,
  UploadObjectDataResponse,
  DeployObjectDataRequest,
  DeployObjectDataResponse,
  GetObjectDataRequest,
  GetObjectDataResponse,
  GetObjectDataPackageRequest,
  GetObjectDataPackageResponse,
  GetObjectDataDeploymentsRequest,
  GetObjectDataDeploymentsResponse,
  GetUserProvisioningDeploymentsRequest,
  GetUserProvisioningDeploymentsResponse,
} from './api'

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: 'application/json' | 'application/protobuf',
    data: object | Uint8Array
  ): Promise<object | Uint8Array>
}

export interface TendoCloudConfiguratorServiceClient {
  CreateCustomer(request: CreateCustomerRequest): Promise<CreateCustomerResponse>
  GetCustomers(request: GetCustomersRequest): Promise<GetCustomersResponse>
  GetCustomer(request: GetCustomerRequest): Promise<GetCustomerResponse>
  GetTenants(request: GetTenantsRequest): Promise<GetTenantsResponse>
  GetTenant(request: GetTenantRequest): Promise<GetTenantResponse>
  GetTenantTemplates(request: GetTenantTemplatesRequest): Promise<GetTenantTemplatesResponse>
  GetTemplates(request: GetTemplatesRequest): Promise<GetTemplatesResponse>
  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse>
  CreateTenantFromTemplate(request: CreateTenantFromTemplateRequest): Promise<CreateTenantFromTemplateResponse>
  GetRenderedTemplate(request: GetRenderedTemplateRequest): Promise<GetRenderedTemplateResponse>
  GetRenderedTemplateFragment(request: GetRenderedTemplateFragmentRequest): Promise<GetRenderedTemplateFragmentResponse>
  UploadTemplate(request: UploadTemplateRequest): Promise<UploadTemplateResponse>
  UpdateTemplate(request: UpdateTemplateRequest): Promise<UpdateTemplateResponse>
  UpdateTemplateGoldenStatus(request: UpdateTemplateGoldenStatusRequest): Promise<UpdateTemplateGoldenStatusResponse>
  UpdateTemplatePublishedStatus(
    request: UpdateTemplatePublishedStatusRequest
  ): Promise<UpdateTemplatePublishedStatusResponse>
  UpdateTemplateEmrlessStatus(request: UpdateTemplateEmrlessStatusRequest): Promise<UpdateTemplateEmrlessStatusResponse>
  DeleteTemplate(request: DeleteTemplateRequest): Promise<DeleteTemplateResponse>
  DestroyTenant(request: DestroyTenantRequest): Promise<DestroyTenantResponse>
  DeleteTenant(request: DeleteTenantRequest): Promise<DeleteTenantResponse>
  GetTenantDestructions(request: GetTenantDestructionsRequest): Promise<GetTenantDestructionsResponse>
  GetTemplateFragmentDeployments(
    request: GetTemplateFragmentDeploymentsRequest
  ): Promise<GetTemplateFragmentDeploymentsResponse>
  InitiateTenantExport(request: InitiateTenantExportRequest): Promise<InitiateTenantExportResponse>
  ListObjectDBClusters(request: ListObjectDBClustersRequest): Promise<ListObjectDBClustersResponse>
  UploadObjectData(request: UploadObjectDataRequest): Promise<UploadObjectDataResponse>
  DeployObjectData(request: DeployObjectDataRequest): Promise<DeployObjectDataResponse>
  GetObjectData(request: GetObjectDataRequest): Promise<GetObjectDataResponse>
  GetObjectDataPackage(request: GetObjectDataPackageRequest): Promise<GetObjectDataPackageResponse>
  GetObjectDataDeployments(request: GetObjectDataDeploymentsRequest): Promise<GetObjectDataDeploymentsResponse>
  GetUserProvisioningDeployments(
    request: GetUserProvisioningDeploymentsRequest
  ): Promise<GetUserProvisioningDeploymentsResponse>
}

export class TendoCloudConfiguratorServiceClientJSON implements TendoCloudConfiguratorServiceClient {
  private readonly rpc: Rpc
  constructor(rpc: Rpc) {
    this.rpc = rpc
    this.CreateCustomer.bind(this)
    this.GetCustomers.bind(this)
    this.GetCustomer.bind(this)
    this.GetTenants.bind(this)
    this.GetTenant.bind(this)
    this.GetTenantTemplates.bind(this)
    this.GetTemplates.bind(this)
    this.GetTemplate.bind(this)
    this.CreateTenantFromTemplate.bind(this)
    this.GetRenderedTemplate.bind(this)
    this.GetRenderedTemplateFragment.bind(this)
    this.UploadTemplate.bind(this)
    this.UpdateTemplate.bind(this)
    this.UpdateTemplateGoldenStatus.bind(this)
    this.UpdateTemplatePublishedStatus.bind(this)
    this.UpdateTemplateEmrlessStatus.bind(this)
    this.DeleteTemplate.bind(this)
    this.DestroyTenant.bind(this)
    this.DeleteTenant.bind(this)
    this.GetTenantDestructions.bind(this)
    this.GetTemplateFragmentDeployments.bind(this)
    this.InitiateTenantExport.bind(this)
    this.ListObjectDBClusters.bind(this)
    this.UploadObjectData.bind(this)
    this.DeployObjectData.bind(this)
    this.GetObjectData.bind(this)
    this.GetObjectDataPackage.bind(this)
    this.GetObjectDataDeployments.bind(this)
    this.GetUserProvisioningDeployments.bind(this)
  }
  CreateCustomer(request: CreateCustomerRequest): Promise<CreateCustomerResponse> {
    const data = CreateCustomerRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'CreateCustomer',
      'application/json',
      data as object
    )
    return promise.then((data) => CreateCustomerResponse.fromJSON(data as any))
  }

  GetCustomers(request: GetCustomersRequest): Promise<GetCustomersResponse> {
    const data = GetCustomersRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetCustomers',
      'application/json',
      data as object
    )
    return promise.then((data) => GetCustomersResponse.fromJSON(data as any))
  }

  GetCustomer(request: GetCustomerRequest): Promise<GetCustomerResponse> {
    const data = GetCustomerRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetCustomer',
      'application/json',
      data as object
    )
    return promise.then((data) => GetCustomerResponse.fromJSON(data as any))
  }

  GetTenants(request: GetTenantsRequest): Promise<GetTenantsResponse> {
    const data = GetTenantsRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTenants',
      'application/json',
      data as object
    )
    return promise.then((data) => GetTenantsResponse.fromJSON(data as any))
  }

  GetTenant(request: GetTenantRequest): Promise<GetTenantResponse> {
    const data = GetTenantRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTenant',
      'application/json',
      data as object
    )
    return promise.then((data) => GetTenantResponse.fromJSON(data as any))
  }

  GetTenantTemplates(request: GetTenantTemplatesRequest): Promise<GetTenantTemplatesResponse> {
    const data = GetTenantTemplatesRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTenantTemplates',
      'application/json',
      data as object
    )
    return promise.then((data) => GetTenantTemplatesResponse.fromJSON(data as any))
  }

  GetTemplates(request: GetTemplatesRequest): Promise<GetTemplatesResponse> {
    const data = GetTemplatesRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTemplates',
      'application/json',
      data as object
    )
    return promise.then((data) => GetTemplatesResponse.fromJSON(data as any))
  }

  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse> {
    const data = GetTemplateRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTemplate',
      'application/json',
      data as object
    )
    return promise.then((data) => GetTemplateResponse.fromJSON(data as any))
  }

  CreateTenantFromTemplate(request: CreateTenantFromTemplateRequest): Promise<CreateTenantFromTemplateResponse> {
    const data = CreateTenantFromTemplateRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'CreateTenantFromTemplate',
      'application/json',
      data as object
    )
    return promise.then((data) => CreateTenantFromTemplateResponse.fromJSON(data as any))
  }

  GetRenderedTemplate(request: GetRenderedTemplateRequest): Promise<GetRenderedTemplateResponse> {
    const data = GetRenderedTemplateRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetRenderedTemplate',
      'application/json',
      data as object
    )
    return promise.then((data) => GetRenderedTemplateResponse.fromJSON(data as any))
  }

  GetRenderedTemplateFragment(
    request: GetRenderedTemplateFragmentRequest
  ): Promise<GetRenderedTemplateFragmentResponse> {
    const data = GetRenderedTemplateFragmentRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetRenderedTemplateFragment',
      'application/json',
      data as object
    )
    return promise.then((data) => GetRenderedTemplateFragmentResponse.fromJSON(data as any))
  }

  UploadTemplate(request: UploadTemplateRequest): Promise<UploadTemplateResponse> {
    const data = UploadTemplateRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UploadTemplate',
      'application/json',
      data as object
    )
    return promise.then((data) => UploadTemplateResponse.fromJSON(data as any))
  }

  UpdateTemplate(request: UpdateTemplateRequest): Promise<UpdateTemplateResponse> {
    const data = UpdateTemplateRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplate',
      'application/json',
      data as object
    )
    return promise.then((data) => UpdateTemplateResponse.fromJSON(data as any))
  }

  UpdateTemplateGoldenStatus(request: UpdateTemplateGoldenStatusRequest): Promise<UpdateTemplateGoldenStatusResponse> {
    const data = UpdateTemplateGoldenStatusRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplateGoldenStatus',
      'application/json',
      data as object
    )
    return promise.then((data) => UpdateTemplateGoldenStatusResponse.fromJSON(data as any))
  }

  UpdateTemplatePublishedStatus(
    request: UpdateTemplatePublishedStatusRequest
  ): Promise<UpdateTemplatePublishedStatusResponse> {
    const data = UpdateTemplatePublishedStatusRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplatePublishedStatus',
      'application/json',
      data as object
    )
    return promise.then((data) => UpdateTemplatePublishedStatusResponse.fromJSON(data as any))
  }

  UpdateTemplateEmrlessStatus(
    request: UpdateTemplateEmrlessStatusRequest
  ): Promise<UpdateTemplateEmrlessStatusResponse> {
    const data = UpdateTemplateEmrlessStatusRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplateEmrlessStatus',
      'application/json',
      data as object
    )
    return promise.then((data) => UpdateTemplateEmrlessStatusResponse.fromJSON(data as any))
  }

  DeleteTemplate(request: DeleteTemplateRequest): Promise<DeleteTemplateResponse> {
    const data = DeleteTemplateRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DeleteTemplate',
      'application/json',
      data as object
    )
    return promise.then((data) => DeleteTemplateResponse.fromJSON(data as any))
  }

  DestroyTenant(request: DestroyTenantRequest): Promise<DestroyTenantResponse> {
    const data = DestroyTenantRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DestroyTenant',
      'application/json',
      data as object
    )
    return promise.then((data) => DestroyTenantResponse.fromJSON(data as any))
  }

  DeleteTenant(request: DeleteTenantRequest): Promise<DeleteTenantResponse> {
    const data = DeleteTenantRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DeleteTenant',
      'application/json',
      data as object
    )
    return promise.then((data) => DeleteTenantResponse.fromJSON(data as any))
  }

  GetTenantDestructions(request: GetTenantDestructionsRequest): Promise<GetTenantDestructionsResponse> {
    const data = GetTenantDestructionsRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTenantDestructions',
      'application/json',
      data as object
    )
    return promise.then((data) => GetTenantDestructionsResponse.fromJSON(data as any))
  }

  GetTemplateFragmentDeployments(
    request: GetTemplateFragmentDeploymentsRequest
  ): Promise<GetTemplateFragmentDeploymentsResponse> {
    const data = GetTemplateFragmentDeploymentsRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTemplateFragmentDeployments',
      'application/json',
      data as object
    )
    return promise.then((data) => GetTemplateFragmentDeploymentsResponse.fromJSON(data as any))
  }

  InitiateTenantExport(request: InitiateTenantExportRequest): Promise<InitiateTenantExportResponse> {
    const data = InitiateTenantExportRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'InitiateTenantExport',
      'application/json',
      data as object
    )
    return promise.then((data) => InitiateTenantExportResponse.fromJSON(data as any))
  }

  ListObjectDBClusters(request: ListObjectDBClustersRequest): Promise<ListObjectDBClustersResponse> {
    const data = ListObjectDBClustersRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'ListObjectDBClusters',
      'application/json',
      data as object
    )
    return promise.then((data) => ListObjectDBClustersResponse.fromJSON(data as any))
  }

  UploadObjectData(request: UploadObjectDataRequest): Promise<UploadObjectDataResponse> {
    const data = UploadObjectDataRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UploadObjectData',
      'application/json',
      data as object
    )
    return promise.then((data) => UploadObjectDataResponse.fromJSON(data as any))
  }

  DeployObjectData(request: DeployObjectDataRequest): Promise<DeployObjectDataResponse> {
    const data = DeployObjectDataRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DeployObjectData',
      'application/json',
      data as object
    )
    return promise.then((data) => DeployObjectDataResponse.fromJSON(data as any))
  }

  GetObjectData(request: GetObjectDataRequest): Promise<GetObjectDataResponse> {
    const data = GetObjectDataRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetObjectData',
      'application/json',
      data as object
    )
    return promise.then((data) => GetObjectDataResponse.fromJSON(data as any))
  }

  GetObjectDataPackage(request: GetObjectDataPackageRequest): Promise<GetObjectDataPackageResponse> {
    const data = GetObjectDataPackageRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetObjectDataPackage',
      'application/json',
      data as object
    )
    return promise.then((data) => GetObjectDataPackageResponse.fromJSON(data as any))
  }

  GetObjectDataDeployments(request: GetObjectDataDeploymentsRequest): Promise<GetObjectDataDeploymentsResponse> {
    const data = GetObjectDataDeploymentsRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetObjectDataDeployments',
      'application/json',
      data as object
    )
    return promise.then((data) => GetObjectDataDeploymentsResponse.fromJSON(data as any))
  }

  GetUserProvisioningDeployments(
    request: GetUserProvisioningDeploymentsRequest
  ): Promise<GetUserProvisioningDeploymentsResponse> {
    const data = GetUserProvisioningDeploymentsRequest.toJSON(request)
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetUserProvisioningDeployments',
      'application/json',
      data as object
    )
    return promise.then((data) => GetUserProvisioningDeploymentsResponse.fromJSON(data as any))
  }
}

export class TendoCloudConfiguratorServiceClientProtobuf implements TendoCloudConfiguratorServiceClient {
  private readonly rpc: Rpc
  constructor(rpc: Rpc) {
    this.rpc = rpc
    this.CreateCustomer.bind(this)
    this.GetCustomers.bind(this)
    this.GetCustomer.bind(this)
    this.GetTenants.bind(this)
    this.GetTenant.bind(this)
    this.GetTenantTemplates.bind(this)
    this.GetTemplates.bind(this)
    this.GetTemplate.bind(this)
    this.CreateTenantFromTemplate.bind(this)
    this.GetRenderedTemplate.bind(this)
    this.GetRenderedTemplateFragment.bind(this)
    this.UploadTemplate.bind(this)
    this.UpdateTemplate.bind(this)
    this.UpdateTemplateGoldenStatus.bind(this)
    this.UpdateTemplatePublishedStatus.bind(this)
    this.UpdateTemplateEmrlessStatus.bind(this)
    this.DeleteTemplate.bind(this)
    this.DestroyTenant.bind(this)
    this.DeleteTenant.bind(this)
    this.GetTenantDestructions.bind(this)
    this.GetTemplateFragmentDeployments.bind(this)
    this.InitiateTenantExport.bind(this)
    this.ListObjectDBClusters.bind(this)
    this.UploadObjectData.bind(this)
    this.DeployObjectData.bind(this)
    this.GetObjectData.bind(this)
    this.GetObjectDataPackage.bind(this)
    this.GetObjectDataDeployments.bind(this)
    this.GetUserProvisioningDeployments.bind(this)
  }
  CreateCustomer(request: CreateCustomerRequest): Promise<CreateCustomerResponse> {
    const data = CreateCustomerRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'CreateCustomer',
      'application/protobuf',
      data
    )
    return promise.then((data) => CreateCustomerResponse.decode(data as Uint8Array))
  }

  GetCustomers(request: GetCustomersRequest): Promise<GetCustomersResponse> {
    const data = GetCustomersRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetCustomers',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetCustomersResponse.decode(data as Uint8Array))
  }

  GetCustomer(request: GetCustomerRequest): Promise<GetCustomerResponse> {
    const data = GetCustomerRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetCustomer',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetCustomerResponse.decode(data as Uint8Array))
  }

  GetTenants(request: GetTenantsRequest): Promise<GetTenantsResponse> {
    const data = GetTenantsRequest.encode(request).finish()
    const promise = this.rpc.request('tcc.v1.TendoCloudConfiguratorService', 'GetTenants', 'application/protobuf', data)
    return promise.then((data) => GetTenantsResponse.decode(data as Uint8Array))
  }

  GetTenant(request: GetTenantRequest): Promise<GetTenantResponse> {
    const data = GetTenantRequest.encode(request).finish()
    const promise = this.rpc.request('tcc.v1.TendoCloudConfiguratorService', 'GetTenant', 'application/protobuf', data)
    return promise.then((data) => GetTenantResponse.decode(data as Uint8Array))
  }

  GetTenantTemplates(request: GetTenantTemplatesRequest): Promise<GetTenantTemplatesResponse> {
    const data = GetTenantTemplatesRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTenantTemplates',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetTenantTemplatesResponse.decode(data as Uint8Array))
  }

  GetTemplates(request: GetTemplatesRequest): Promise<GetTemplatesResponse> {
    const data = GetTemplatesRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTemplates',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetTemplatesResponse.decode(data as Uint8Array))
  }

  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse> {
    const data = GetTemplateRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTemplate',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetTemplateResponse.decode(data as Uint8Array))
  }

  CreateTenantFromTemplate(request: CreateTenantFromTemplateRequest): Promise<CreateTenantFromTemplateResponse> {
    const data = CreateTenantFromTemplateRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'CreateTenantFromTemplate',
      'application/protobuf',
      data
    )
    return promise.then((data) => CreateTenantFromTemplateResponse.decode(data as Uint8Array))
  }

  GetRenderedTemplate(request: GetRenderedTemplateRequest): Promise<GetRenderedTemplateResponse> {
    const data = GetRenderedTemplateRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetRenderedTemplate',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetRenderedTemplateResponse.decode(data as Uint8Array))
  }

  GetRenderedTemplateFragment(
    request: GetRenderedTemplateFragmentRequest
  ): Promise<GetRenderedTemplateFragmentResponse> {
    const data = GetRenderedTemplateFragmentRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetRenderedTemplateFragment',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetRenderedTemplateFragmentResponse.decode(data as Uint8Array))
  }

  UploadTemplate(request: UploadTemplateRequest): Promise<UploadTemplateResponse> {
    const data = UploadTemplateRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UploadTemplate',
      'application/protobuf',
      data
    )
    return promise.then((data) => UploadTemplateResponse.decode(data as Uint8Array))
  }

  UpdateTemplate(request: UpdateTemplateRequest): Promise<UpdateTemplateResponse> {
    const data = UpdateTemplateRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplate',
      'application/protobuf',
      data
    )
    return promise.then((data) => UpdateTemplateResponse.decode(data as Uint8Array))
  }

  UpdateTemplateGoldenStatus(request: UpdateTemplateGoldenStatusRequest): Promise<UpdateTemplateGoldenStatusResponse> {
    const data = UpdateTemplateGoldenStatusRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplateGoldenStatus',
      'application/protobuf',
      data
    )
    return promise.then((data) => UpdateTemplateGoldenStatusResponse.decode(data as Uint8Array))
  }

  UpdateTemplatePublishedStatus(
    request: UpdateTemplatePublishedStatusRequest
  ): Promise<UpdateTemplatePublishedStatusResponse> {
    const data = UpdateTemplatePublishedStatusRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplatePublishedStatus',
      'application/protobuf',
      data
    )
    return promise.then((data) => UpdateTemplatePublishedStatusResponse.decode(data as Uint8Array))
  }

  UpdateTemplateEmrlessStatus(
    request: UpdateTemplateEmrlessStatusRequest
  ): Promise<UpdateTemplateEmrlessStatusResponse> {
    const data = UpdateTemplateEmrlessStatusRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UpdateTemplateEmrlessStatus',
      'application/protobuf',
      data
    )
    return promise.then((data) => UpdateTemplateEmrlessStatusResponse.decode(data as Uint8Array))
  }

  DeleteTemplate(request: DeleteTemplateRequest): Promise<DeleteTemplateResponse> {
    const data = DeleteTemplateRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DeleteTemplate',
      'application/protobuf',
      data
    )
    return promise.then((data) => DeleteTemplateResponse.decode(data as Uint8Array))
  }

  DestroyTenant(request: DestroyTenantRequest): Promise<DestroyTenantResponse> {
    const data = DestroyTenantRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DestroyTenant',
      'application/protobuf',
      data
    )
    return promise.then((data) => DestroyTenantResponse.decode(data as Uint8Array))
  }

  DeleteTenant(request: DeleteTenantRequest): Promise<DeleteTenantResponse> {
    const data = DeleteTenantRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DeleteTenant',
      'application/protobuf',
      data
    )
    return promise.then((data) => DeleteTenantResponse.decode(data as Uint8Array))
  }

  GetTenantDestructions(request: GetTenantDestructionsRequest): Promise<GetTenantDestructionsResponse> {
    const data = GetTenantDestructionsRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTenantDestructions',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetTenantDestructionsResponse.decode(data as Uint8Array))
  }

  GetTemplateFragmentDeployments(
    request: GetTemplateFragmentDeploymentsRequest
  ): Promise<GetTemplateFragmentDeploymentsResponse> {
    const data = GetTemplateFragmentDeploymentsRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetTemplateFragmentDeployments',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetTemplateFragmentDeploymentsResponse.decode(data as Uint8Array))
  }

  InitiateTenantExport(request: InitiateTenantExportRequest): Promise<InitiateTenantExportResponse> {
    const data = InitiateTenantExportRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'InitiateTenantExport',
      'application/protobuf',
      data
    )
    return promise.then((data) => InitiateTenantExportResponse.decode(data as Uint8Array))
  }

  ListObjectDBClusters(request: ListObjectDBClustersRequest): Promise<ListObjectDBClustersResponse> {
    const data = ListObjectDBClustersRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'ListObjectDBClusters',
      'application/protobuf',
      data
    )
    return promise.then((data) => ListObjectDBClustersResponse.decode(data as Uint8Array))
  }

  UploadObjectData(request: UploadObjectDataRequest): Promise<UploadObjectDataResponse> {
    const data = UploadObjectDataRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'UploadObjectData',
      'application/protobuf',
      data
    )
    return promise.then((data) => UploadObjectDataResponse.decode(data as Uint8Array))
  }

  DeployObjectData(request: DeployObjectDataRequest): Promise<DeployObjectDataResponse> {
    const data = DeployObjectDataRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'DeployObjectData',
      'application/protobuf',
      data
    )
    return promise.then((data) => DeployObjectDataResponse.decode(data as Uint8Array))
  }

  GetObjectData(request: GetObjectDataRequest): Promise<GetObjectDataResponse> {
    const data = GetObjectDataRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetObjectData',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetObjectDataResponse.decode(data as Uint8Array))
  }

  GetObjectDataPackage(request: GetObjectDataPackageRequest): Promise<GetObjectDataPackageResponse> {
    const data = GetObjectDataPackageRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetObjectDataPackage',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetObjectDataPackageResponse.decode(data as Uint8Array))
  }

  GetObjectDataDeployments(request: GetObjectDataDeploymentsRequest): Promise<GetObjectDataDeploymentsResponse> {
    const data = GetObjectDataDeploymentsRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetObjectDataDeployments',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetObjectDataDeploymentsResponse.decode(data as Uint8Array))
  }

  GetUserProvisioningDeployments(
    request: GetUserProvisioningDeploymentsRequest
  ): Promise<GetUserProvisioningDeploymentsResponse> {
    const data = GetUserProvisioningDeploymentsRequest.encode(request).finish()
    const promise = this.rpc.request(
      'tcc.v1.TendoCloudConfiguratorService',
      'GetUserProvisioningDeployments',
      'application/protobuf',
      data
    )
    return promise.then((data) => GetUserProvisioningDeploymentsResponse.decode(data as Uint8Array))
  }
}
