import { Alert, Box, Button, Modal, SpaceBetween } from '@cloudscape-design/components'
import { useModalData } from './ModalContext'
import { useState } from 'react'
import { UploadObjectData } from './UploadObjectData'
import { deployObjectData, submitObjectData } from 'lib/template'

export const DeployTenantDataModalName = 'DeployTenantDataModal'

interface DeployTenantDataModalProps {
  tenantId: string
}

export const DeployTenantDataModal = ({ tenantId }: DeployTenantDataModalProps) => {
  const [file, setFile] = useState<File>()
  const [objectDataIds, setObjectDataIds] = useState<string[]>([])
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [deployLoading, setDeployLoading] = useState<boolean>(false)

  const handleSelectFile = async (f?: File) => {
    setFile(f)
    if (!f) setObjectDataIds([])
  }

  const handleUploadFile = async () => {
    setUploadLoading(true)
    const ods = await submitObjectData(file!)
    setObjectDataIds(ods.map((od) => od.id))
    setUploadLoading(false)
  }

  const handleDeployTenantData = async () => {
    setDeployLoading(true)
    await deployObjectData(tenantId, objectDataIds)
    disableModal()
    window.location.reload()
  }

  const canDeploy = objectDataIds.length > 0

  const { visible, disableModal } = useModalData(DeployTenantDataModalName)

  return (
    <Modal
      header="Deploy Data"
      onDismiss={disableModal}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeployTenantData} disabled={!canDeploy} loading={deployLoading}>
              Deploy
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        <Box>
          <UploadObjectData onFileSelect={handleSelectFile} />
        </Box>
        {file && (
          <Box>
            <Button variant="primary" loading={uploadLoading} onClick={handleUploadFile}>
              Upload
            </Button>
          </Box>
        )}
        {canDeploy && (
          <Alert type="success">Upload successful! Click &apos;Deploy&apos; to upsert object data to the tenant</Alert>
        )}
      </SpaceBetween>
    </Modal>
  )
}
