import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// The home page immediately redirects to either a direct URL or defaults
// to the customers page.
//
// In a future world where there is more complexity on the site,
// perhaps a landing page with multiple options and branching
// UI could be useful. For now, it's unnecessary.
export const Home = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(window.location.pathname === '/' ? '/customers' : window.location.pathname)
  })
  return <></>
}
