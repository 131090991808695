import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import {
  TextContent,
  BreadcrumbGroup,
  Spinner,
  Box,
  Header,
  SpaceBetween,
  Container,
  ButtonDropdown,
  ButtonDropdownProps,
  Alert,
  ColumnLayout,
  Link,
} from '@cloudscape-design/components'
import { ObjectData, Template } from 'twirp/api'
import { useState } from 'react'
import { TCCService } from 'services/api'
import { useQuery } from 'react-query'
import { TemplateFragmentTable } from 'components/TemplateFragmentTable'
import {
  changeEmrlessStatus,
  changeGoldenStatus,
  deleteTemplate,
  downloadObjectDataPackage,
  downloadTemplate,
  fetchObjectData,
  publishTemplate,
} from 'lib/template'
import { useModalEnabler } from 'components/ModalContext'
import { TemplateContentModal, TemplateContentModalName } from 'components/TemplateContentModal'
import { ConfirmationModal, ConfirmationModalName, ConfirmationModalProps } from 'components/ConfirmationModal'
import { ValueWithLabel } from 'components/ValueWithLabel'
import { TemplateBadge } from 'components/TemplateBadge'
import { TemplateEditModal, TemplateEditModalName } from 'components/TemplateEditModal'
import { useTenant } from 'lib/tenant'
import { ObjectDataTable } from 'components/ObjectDataTable'
import { UploadObjectDataModal, UploadObjectDataModalName } from 'components/UploadObjectDataModal'

export const TemplatePage = () => {
  const { templateId } = useParams()
  const navigate = useNavigate()

  const { enableModal } = useModalEnabler()

  const [template, setTemplate] = useState<Template>()
  const [objectData, setObjectData] = useState<ObjectData[]>([])

  const { isLoading, refetch } = useQuery('getTemplate', async () => {
    const resp = await TCCService.GetTemplate({
      templateId: templateId!,
    })
    setTemplate(resp.template)

    const objectDataResp = await fetchObjectData(templateId!)
    setObjectData(objectDataResp)

    return resp.template
  })

  const { tenant } = useTenant(template?.createdFromTenantId || '')

  const modalPropMap = confirmationModalPropsMap(template!, refetch, navigate)
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>(modalPropMap.delete)

  const sortedTemplateFragments = template?.templateFragments.sort((a, b) => a.name.localeCompare(b.name))

  let templateViewable = true
  sortedTemplateFragments?.forEach(
    (tf) => (templateViewable = templateViewable && tf.status.toLowerCase() === 'success')
  )

  const sortedObjectData = objectData.sort((a, b) => a.model.localeCompare(b.model))

  const containsPersonData = (objectData: ObjectData[]) => {
    return objectData.find((od) => od.model == 'Person' && od.numberRecords > 0) != undefined
  }

  const templatePublishable =
    templateViewable &&
    objectData.filter((od) => od.status.toLowerCase() !== 'success' && od.status.toLowerCase() !== 'no_records')
      .length === 0

  const goldenItem = template?.golden
    ? { text: 'Unmark Golden', id: 'disableGolden', iconName: 'unlocked' }
    : { text: 'Mark Golden', id: 'enableGolden', iconName: 'lock-private' }

  const emrlessItem = template?.emrless
    ? { text: 'Unmark Emrless', id: 'disableEmrless', iconName: 'user-profile' }
    : {
        text: 'Mark Emrless',
        id: 'enableEmrless',
        iconName: 'user-profile-active',
        disabled: !template?.includesObjectData || !containsPersonData(template?.objectData),
        disabledReason: 'Templates must contain Person object data to be marked EMRless',
      }

  const handleActionItemClick = (item: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    switch (item.detail.id) {
      case 'disableGolden': {
        setConfirmationModalProps(modalPropMap.disableGolden)
        enableModal(ConfirmationModalName)
        break
      }
      case 'enableGolden': {
        setConfirmationModalProps(modalPropMap.enableGolden)
        enableModal(ConfirmationModalName)
        break
      }
      case 'disableEmrless': {
        setConfirmationModalProps(modalPropMap.disableEmrless)
        enableModal(ConfirmationModalName)
        break
      }
      case 'enableEmrless': {
        setConfirmationModalProps(modalPropMap.enableEmrless)
        enableModal(ConfirmationModalName)
        break
      }
      case 'view': {
        enableModal(TemplateContentModalName)
        break
      }
      case 'download': {
        downloadTemplate(template!)
        break
      }
      case 'publish': {
        setConfirmationModalProps(modalPropMap.publish)
        enableModal(ConfirmationModalName)
        break
      }
      case 'delete': {
        setConfirmationModalProps(modalPropMap.delete)
        enableModal(ConfirmationModalName)
        break
      }
      case 'edit': {
        enableModal(TemplateEditModalName)
        break
      }
      case 'addObjectData': {
        enableModal(UploadObjectDataModalName)
        break
      }
      case 'downloadObjectData': {
        downloadObjectDataPackage(templateId!)
        break
      }
    }
  }

  if (isLoading) {
    return <Spinner></Spinner>
  }

  return (
    <Box padding="m">
      <BreadcrumbGroup
        items={[
          { text: 'Templates', href: '/templates' },
          { text: template?.name || '', href: '' },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <SpaceBetween size="m">
        <Header
          variant="h1"
          actions={
            <ButtonDropdown
              items={[
                {
                  text: 'View',
                  id: 'view',
                  iconName: 'expand',
                  disabled: !templateViewable,
                },
                {
                  text: 'Edit',
                  id: 'edit',
                  iconName: 'edit',
                  disabled: template?.golden,
                },
                { text: 'Download', id: 'download', iconName: 'download' },
                goldenItem as ButtonDropdownProps.ItemOrGroup,
                emrlessItem as ButtonDropdownProps.ItemOrGroup,
                {
                  text: 'Publish',
                  id: 'publish',
                  iconName: 'check',
                  disabled: template?.published || !templatePublishable,
                },
                {
                  text: 'Delete',
                  id: 'delete',
                  iconName: 'remove',
                  disabled: template?.golden,
                },
                {
                  text: 'Add Object Data',
                  id: 'addObjectData',
                  iconName: 'add-plus',
                  disabled: template?.golden,
                },
                {
                  text: 'Download Object Data',
                  id: 'downloadObjectData',
                  iconName: 'download',
                  disabled: !template?.includesObjectData,
                },
              ]}
              onItemClick={handleActionItemClick}
            >
              Actions
            </ButtonDropdown>
          }
        >
          <SpaceBetween size="m" direction="horizontal">
            <TextContent>
              <h1>{template?.name}</h1>
            </TextContent>
          </SpaceBetween>
        </Header>
        {template?.golden && (
          <Alert header="Golden Template" type="warning">
            This template carries the &quot;Golden&quot; designation, meaning it should not be editted or deleted.
            Please take caution.
          </Alert>
        )}
        {template?.archived && (
          <Alert header="Archived Template" type="warning">
            This template has been archived. It can no longer be deployed to tenants.
          </Alert>
        )}
        {!template?.published && (
          <Alert header="Draft Template" type="warning">
            This template is in the draft state. When you have verified it is working as intended, use the Actions
            dropdown to publish it.
          </Alert>
        )}
        <Container header={<Header>Overview</Header>}>
          <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Description">{template?.description}</ValueWithLabel>
            </SpaceBetween>

            <SpaceBetween size="l">
              <ValueWithLabel label="Status">
                <TemplateBadge template={template} />
              </ValueWithLabel>
              <ValueWithLabel label="Creation Time">
                <TextContent>
                  {template?.createdAt?.toLocaleDateString()} {template?.createdAt?.toLocaleTimeString()}
                </TextContent>
              </ValueWithLabel>
              <ValueWithLabel label="Emrless">
                <TextContent>{template?.emrless ? 'Yes' : 'No'}</TextContent>
              </ValueWithLabel>
              {tenant && (
                <ValueWithLabel label="Created From Tenant">
                  <Link href={`/customers/${tenant.customerId}/tenants/${tenant.id}`}>{tenant.name}</Link>
                </ValueWithLabel>
              )}
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        {template && <TemplateFragmentTable templateFragments={sortedTemplateFragments || []} />}

        {template && template.includesObjectData && <ObjectDataTable objectData={sortedObjectData} />}

        {template && (
          <>
            <TemplateContentModal templateId={template.id}></TemplateContentModal>
            <ConfirmationModal {...confirmationModalProps} />
            <TemplateEditModal template={template} />
            <UploadObjectDataModal template={template} />
          </>
        )}
      </SpaceBetween>
    </Box>
  )
}

const confirmationModalPropsMap = (
  template: Template,
  refetcher: () => Promise<unknown>,
  navigate: NavigateFunction
): { [key: string]: ConfirmationModalProps } => {
  return {
    enableGolden: {
      title: 'Mark Template Golden',
      body: 'Are you sure you want to designate this template as "Golden"?',
      onConfirm: async () => {
        await changeGoldenStatus(template, true)
        await refetcher()
      },
    },
    disableGolden: {
      title: 'Remove Golden Status',
      body: 'Are you sure you want to remove the "Golden" designation from this template?',
      onConfirm: async () => {
        await changeGoldenStatus(template, false)
        await refetcher()
      },
    },
    enableEmrless: {
      title: 'Mark Template Emrless',
      body: 'Are you sure you want to designate this template as "Emrless"?',
      onConfirm: async () => {
        await changeEmrlessStatus(template, true)
        await refetcher()
      },
    },
    disableEmrless: {
      title: 'Remove Emrless Status',
      body: 'Are you sure you want to remove the "Emrless" designation from this template?',
      onConfirm: async () => {
        await changeEmrlessStatus(template, false)
        await refetcher()
      },
    },
    delete: {
      title: 'Delete Template',
      body: 'Are you sure you want to delete this template?',
      onConfirm: async () => {
        await deleteTemplate(template)
        navigate(`/templates`)
      },
    },
    publish: {
      title: 'Publish Template',
      body: 'Are you sure you want to publish this template? This cannot be undone.',
      onConfirm: async () => {
        await publishTemplate(template)
        await refetcher()
      },
    },
  }
}
