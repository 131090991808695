import { Box } from '@cloudscape-design/components'

export const EmptyTableMessage = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
    </Box>
  )
}
