import { Header, StatusIndicatorProps, Table, TableProps } from '@cloudscape-design/components'
import { formatServiceName } from 'lib/tenant-services'
import { StatusIcon } from './StatusIcon'
import { EmptyTableMessage } from './EmptyTableMessage'

interface ServiceStatusItem {
  name: string
  status: string
  errorMessage: string
}

export const TemplateFragmentDeploymentTable = ({
  serviceStatuses,
  tableVariant,
  includeHeader,
  isLoading,
}: {
  serviceStatuses: ServiceStatusItem[] | undefined
  tableVariant?: TableProps.Variant
  includeHeader?: boolean
  isLoading?: boolean
}) => {
  const statusMap: { [key: string]: StatusIndicatorProps.Type } = {
    UNKNOWN: 'warning',
    SUCCESS: 'success',
    PENDING: 'pending',
    FAILURE: 'error',
    DESTROY_PENDING: 'pending',
    DESTROY_SUCCESS: 'success',
    DESTROY_FAILURE: 'error',
  }

  return (
    <Table
      columnDefinitions={[
        {
          id: 'serviceName',
          header: 'Service',
          cell: (item) => formatServiceName(item.name),
          sortingField: 'service',
          minWidth: '4rem',
          maxWidth: '6rem',
        },
        {
          id: 'error',
          header: 'Message',
          cell: (item) => item.errorMessage,
          sortingField: 'status',
          width: '25rem',
          minWidth: '10rem',
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => <StatusIcon item={item} mapping={statusMap} />,
          sortingField: 'status',
          minWidth: '4rem',
          maxWidth: '6rem',
        },
      ]}
      items={serviceStatuses ? serviceStatuses : []}
      loading={isLoading}
      loadingText="Loading service statuses"
      trackBy="id"
      resizableColumns
      visibleColumns={['serviceName', 'status', 'error']}
      header={includeHeader && <Header>Configuration</Header>}
      variant={tableVariant}
      wrapLines
      empty={
        <EmptyTableMessage
          title="No provisioned services"
          subtitle="No services provisioned with templating at tenant creation"
        />
      }
    />
  )
}
