import axios from 'axios'
import { TendoCloudConfiguratorServiceClientJSON } from 'twirp/api.twirp-client'
import TokenManager from './TokenManager'

// @todo pull in url that env aware
// const API_URL = process.env.REACT_API_URL
// vvvvvv nherson TCE deployment vvvvvv
// const API_URL = 'http://localhost:3000'
// const API_URL = 'https://7as9o5e7m5.execute-api.us-east-1.amazonaws.com'
const API_URL = process.env.REACT_APP_API_URL

interface RPC {
  request(
    service: string,
    method: string,
    contentType: 'application/json' | 'application/protobuf',
    data: Record<string, unknown> | Uint8Array
  ): Promise<Record<string, unknown> | Uint8Array>
}

const client = axios.create({
  baseURL: `${API_URL}/twirp`,
})

client.interceptors.request.use(
  async (config) => {
    const token = await TokenManager.getInstance().getAccessToken()
    if (token && config.headers) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => Promise.reject({ error })
)

export const axiosRPC: RPC = {
  async request(service, method, contentType, data) {
    const res = await client.post(`${service}/${method}`, data, {
      responseType: contentType === 'application/protobuf' ? 'arraybuffer' : 'json',
      headers: { 'content-type': contentType },
    })
    return res?.data
  },
}

export const TCCService = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
