import { useState } from 'react'
import { useQuery } from 'react-query'
import { TCCService } from 'services/api'
import { Tenant } from 'twirp/api'

export const useTenant = (id: string) => {
  const [tenant, setTenant] = useState<Tenant | undefined>()

  const { isLoading, refetch, error } = useQuery(`tenant-${id}`, async () => {
    let t: Tenant | undefined
    if (id !== '') {
      const resp = await TCCService.GetTenant({
        tenantId: id,
      })
      t = resp.tenant
    }

    setTenant(t)
    return t
  })

  return {
    tenant,
    isLoading,
    error,
    refetch,
  }
}

export const useDBClusterOptions = () => {
  const [dbClusters, setDbClusters] = useState<string[]>(['default'])

  const { isLoading, refetch, error } = useQuery(`dbClusters`, async () => {
    const resp = await TCCService.ListObjectDBClusters({})
    // always push "default" to the front
    setDbClusters(['default', ...resp.dbClusters.filter((n) => n !== 'default')])
    return dbClusters
  })

  return {
    dbClusters,
    isLoading,
    error,
    refetch,
  }
}
