import { Alert, Box, Button, Modal, SpaceBetween, TextContent } from '@cloudscape-design/components'
import { Tenant } from 'twirp/api'
import { useModalData } from './ModalContext'
import { TCCService } from 'services/api'

export const DestroyTenantModalName = 'DestroyTenantModal'

interface DestroyTenantModalProps {
  tenant: Tenant
}

export const DestroyTenantModal = (props: DestroyTenantModalProps) => {
  const { visible, disableModal } = useModalData(DestroyTenantModalName)

  const handleDestroyTenant = async () => {
    await TCCService.DestroyTenant({
      tenantId: props.tenant.id,
    })
    disableModal()
    window.location.reload()
  }
  return (
    <Modal
      header="Destroy Tenant"
      onDismiss={disableModal}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDestroyTenant}>
              Destroy
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Box padding="s">
        <Box>
          <TextContent>
            <h5>Tenant Name</h5>
            <p>{props.tenant.name}</p>
          </TextContent>
        </Box>

        <Box>
          <TextContent>
            <h5>Tenant ID</h5>
            <p>{props.tenant.id}</p>
          </TextContent>
        </Box>
      </Box>
      <Alert type="warning">
        Choosing to destroy this tenant will immediately initiate removal of all data resources at Tendo belonging to
        it. Are you sure you want to continue?
      </Alert>
    </Modal>
  )
}
