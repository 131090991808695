import { Button, Box, Modal, SpaceBetween, Alert } from '@cloudscape-design/components'
import { useState } from 'react'
import { useModalData } from './ModalContext'
import { submitObjectData } from 'lib/template'
import { Template } from 'twirp/api'
import { UploadObjectData } from './UploadObjectData'

export const UploadObjectDataModalName = 'UploadObjectDataModal'

export interface UploadObjectDataModalProps {
  template: Template
}

export const UploadObjectDataModal = ({ template }: UploadObjectDataModalProps) => {
  const [f, setF] = useState<File>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleFileSelect = (f?: File) => {
    setF(f)
  }

  const { disableModal, visible } = useModalData(UploadObjectDataModalName)
  const handleSubmitObjectData = async () => {
    setIsLoading(true)
    await submitObjectData(f!, template?.id)
    disableModal()
    window.location.reload()
  }

  return (
    <Modal
      onDismiss={disableModal}
      visible={visible}
      header="Upload object data"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!f} onClick={handleSubmitObjectData} loading={isLoading}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <UploadObjectData onFileSelect={handleFileSelect} />
        {template.includesObjectData && (
          <Alert type="warning">Uploading object data to this template will overwrite current object data</Alert>
        )}
      </SpaceBetween>
    </Modal>
  )
}
