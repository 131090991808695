import { Spinner } from '@cloudscape-design/components'
import { useTemplate } from 'lib/template'
import { useEffect, useState } from 'react'

import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import jsonHighlighting from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
SyntaxHighlighter.registerLanguage('json', jsonHighlighting)

export interface TemplateContentProps {
  templateId: string
}

export const TemplateContent = (props: TemplateContentProps) => {
  const { renderedTemplate, loading, error } = useTemplate(props.templateId)
  const [jsonString, setJSONString] = useState(
    'Parsing the JSON template. This may take a few moments, and may lock up the web page! Sorry, blame JavaScript :)'
  )

  useEffect(() => {
    setJSONString(JSON.stringify(renderedTemplate, null, 2) || jsonString)
  }, [jsonString, renderedTemplate])

  if (error) {
    setJSONString(error.message)
  } else if (loading) {
    return <Spinner />
  }

  return (
    <SyntaxHighlighter language="json" style={docco}>
      {jsonString}
    </SyntaxHighlighter>
  )
}
