import { Box, StatusIndicator, StatusIndicatorProps } from '@cloudscape-design/components'
import Case from 'case'

export interface ItemWithStatus {
  status: string
  updatedAt?: Date
}

export interface StatusIconProps {
  item: ItemWithStatus
  mapping: { [key: string]: StatusIndicatorProps.Type }
  // A number of seconds. If the item.updatedAt is present,
  // the item.status is PENDING, and the update time is older than
  // timeoutSeconds, will render the status as "timeout"
  timeoutSeconds?: number
}

export const StatusIcon = (props: StatusIconProps) => {
  const item = props.item

  const now = new Date().getTime()
  const ts = item.updatedAt?.getTime() || now
  const timeout = props.timeoutSeconds && now - ts > props.timeoutSeconds * 1000

  let statusIcon = props.mapping[item.status] || 'warning'
  let msg = Case.title(item.status)
  if (item.status == 'PENDING' && timeout) {
    msg = 'Timeout'
    statusIcon = 'error'
  }

  return (
    <Box>
      <StatusIndicator type={statusIcon as StatusIndicatorProps.Type}>{msg}</StatusIndicator>
    </Box>
  )
}
