import { ReactNode, createContext, useContext, useState } from 'react'

const ModalContext = createContext({
  name: '',
  update: (_s: string) => {},
})

export const ModalProvider = (props: { children: ReactNode }) => {
  const [modalEnabled, setModalEnabled] = useState('')

  return (
    <ModalContext.Provider value={{ name: modalEnabled, update: (m: string) => setModalEnabled(m) }}>
      {props.children}
    </ModalContext.Provider>
  )
}

export const useModalData = (name: string) => {
  const { name: active, update } = useContext(ModalContext)

  return {
    visible: active === name,
    disableModal: () => update(''),
  }
}

export const useModalEnabler = () => {
  const { update } = useContext(ModalContext)
  return {
    enableModal: (n: string) => update(n),
  }
}
