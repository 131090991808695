import { Badge } from '@cloudscape-design/components'
import { Template } from 'twirp/api'

export const TemplateBadge = ({ template }: { template?: Template }) => {
  if (!template) {
    return <></>
  }

  let color: 'red' | 'grey' | 'blue' | 'green' | undefined
  let status: string
  if (template.archived) {
    color = 'red'
    status = 'Archived'
  } else if (!template.published) {
    color = 'grey'
    status = 'Draft'
  } else if (!template.golden) {
    color = 'blue'
    status = 'Published'
  } else {
    color = 'green'
    status = 'Golden'
  }

  return <Badge color={color}>{status}</Badge>
}
