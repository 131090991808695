import { Route, Routes } from 'react-router-dom'
import { Customers } from 'pages/Customers'
import { NewCustomer } from 'pages/NewCustomer'
import { CustomerPage } from 'pages/Customer'
import { Home } from 'pages/Home'

import { AppLayout } from '@cloudscape-design/components'
import TopNavigation from '@cloudscape-design/components/top-navigation'

import SideNavigation from '@cloudscape-design/components/side-navigation'
import { useAuth0 } from '@auth0/auth0-react'
import { Logout } from 'pages/Logout'
import { TenantPage } from 'pages/Tenant'
import { NewTenant } from 'pages/NewTenant'
import { FlashbarContext, FlashMessages, useFlashMessages } from './FlashMessage'
import { TemplatesPage } from 'pages/Templates'
import { TemplatePage } from 'pages/Template'
import { ModalProvider } from './ModalContext'

function AuthenticatedApp(): JSX.Element {
  const { user } = useAuth0()
  const { flashMessages, pushFlashMessage, removeFlashMessage } = useFlashMessages()
  return (
    <>
      <FlashbarContext.Provider
        value={{
          messages: flashMessages,
          pushMessage: pushFlashMessage,
          removeMessage: removeFlashMessage,
        }}
      >
        <ModalProvider>
          <TopNavigation
            identity={{
              href: '#',
              title: 'Tendo Cloud Configurator',
            }}
            data-testid="top-navigation"
            utilities={[
              {
                type: 'menu-dropdown',
                text: user?.name,
                description: user?.email,
                iconName: 'user-profile',
                items: [
                  {
                    id: 'documentation',
                    text: 'Documentation TODO',
                    href: 'https://google.com',
                    external: true,
                  },
                  { id: 'signout', text: 'Sign out', href: '/logout' },
                ],
              },
            ]}
            i18nStrings={{
              searchIconAriaLabel: 'Search',
              searchDismissIconAriaLabel: 'Close search',
              overflowMenuTriggerText: 'More',
              overflowMenuTitleText: 'All',
              overflowMenuBackIconAriaLabel: 'Back',
              overflowMenuDismissIconAriaLabel: 'Close menu',
            }}
          />
          <AppLayout
            disableContentHeaderOverlap={true}
            toolsHide={true}
            navigation={
              <SideNavigation
                header={{
                  text: 'Tools',
                  href: '/',
                }}
                data-testid="side-navigation"
                items={[
                  {
                    text: 'Customer Management',
                    type: 'link',
                    href: '/customers',
                  },
                  {
                    text: 'Create Tenant from Template',
                    type: 'link',
                    href: '/tenants/new',
                  },
                  {
                    text: 'Templates',
                    type: 'link',
                    href: '/templates',
                  },
                ]}
              />
            }
            content={
              <>
                <FlashMessages />
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="/customers/:customerId" element={<CustomerPage />} />
                  <Route path="/customers/:customerId/tenants/:tenantId" element={<TenantPage />} />
                  <Route path="/customers/new" element={<NewCustomer />} />
                  <Route path="/tenants/new" element={<NewTenant />} />
                  <Route path="/templates" element={<TemplatesPage />} />
                  <Route path="/templates/:templateId" element={<TemplatePage />} />
                  <Route path="/logout" element={<Logout />} />
                </Routes>
              </>
            }
          />
        </ModalProvider>
      </FlashbarContext.Provider>
    </>
  )
}

export default AuthenticatedApp
