import Case from 'case'
import { Tenant } from 'twirp/api'

export const allTenantServicesSuccessfullyDestroyed = (tenant: Tenant): boolean => {
  for (const svc in tenant.serviceStatuses) {
    if (tenant.serviceStatuses[svc] !== 'DESTROY_SUCCESS') {
      return false
    }
  }
  return true
}

export const allTenantServicesSuccessfullyConfigured = (tenant: Tenant): boolean => {
  for (const svc of tenant.templateFragmentDeployments) {
    if (svc.status !== 'SUCCESS') {
      return false
    }
  }
  return true
}

export const tenantFailedServiceConfiguration = (tenant: Tenant): boolean => {
  for (const svc of tenant.templateFragmentDeployments) {
    if (svc.status === 'FAILURE') {
      return true
    }
  }
  return false
}

export const formatServiceName = (name: string): string => {
  if (name.toLocaleLowerCase() === 'sdui') {
    return 'SDUI'
  }
  return Case.title(name)
}
