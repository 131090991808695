// @todo: Write tests -- seriously **Jeff Hudzik**

type TokenRetriever = () => Promise<string>

class TokenManager {
  private static _instance: TokenManager
  private _getAccessToken!: TokenRetriever

  public static getInstance(): TokenManager {
    if (!TokenManager._instance) {
      TokenManager._instance = new TokenManager()
    }
    return TokenManager._instance
  }

  public set getAccessToken(tr: TokenRetriever) {
    this._getAccessToken = tr
  }
  public get getAccessToken(): TokenRetriever {
    // @todo: Can we return rejected Promise if _getAccessToken is undefined
    return this._getAccessToken
  }
}

export default TokenManager
